export class VehicleInformationData {
  constructor(
    id,
    // Basic information
    year,
    make,
    model,
    odometer,
    odometerUnit,
    vin,
    stockNumber,
    dealer_name,
    // Vehicle details
    drivetrain,
    transmission,
    exteriorColor,
    interiorColor,
    trim,
    description,
    // Photos
    images,
    // Listing details
    price,
    currency,
    interest_rate,
    product_category,
    residuals,
    availability,
    includeOnLeasing,

    creation_date
  ) {
    // Basic Info
    this.id = id;
    this.year = year;
    this.make = make;
    this.model = model;
    this.odometer = odometer;
    this.odometerUnit = odometerUnit;
    this.vin = vin;
    this.stockNumber = stockNumber;
    this.dealer_name = dealer_name;
    // Vehicle details
    this.drivetrain = drivetrain;
    this.transmission = transmission;
    this.exteriorColor = exteriorColor;
    this.interiorColor = interiorColor;
    this.trim = trim;
    this.description = description;
    // Photo
    this.images = images;
    // Listing details
    this.price = price;
    this.currency = currency;
    this.interest_rate = interest_rate;
    this.product_category = product_category;
    this.residuals = residuals;
    this.availability = availability;
    this.includeOnLeasing = includeOnLeasing;
    this.creation_date = creation_date;
  }
}

export const defaultObjectFormat = new VehicleInformationData(
  // Basic info
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  // Vehicle Details
  "",
  "",
  "",
  "",
  "",
  "",
  //Photo
  [],

  // Listing details
  "",
  "",
  "",
  "",
  [],
  "",
  "",
  "",
  ""
);
