import VehicleList from "./vehicle-table/Vehicle-List";
import HeaderMaintable from "./Main-Table-Header";
import MainTableFooter from "./Main-Table-Footer";
import SearchBar from "./Search-Bar";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";

// eslint-disable-next-line no-unused-vars
import { Redirect } from "react-router";
import { setShowToasts, setShowBlueFlag } from "../store/toast-message-slice";

export default function SoldArchive() {
  const userTokenKey = localStorage.getItem("TOKEN_KEY");
  // redirects user to login page is they are not authorized
  if (!userTokenKey) {
    return <Redirect to="/" />;
  }

  const dispatch = useDispatch();
  const showToastStatus = useSelector(
    (state) => state.toast_messages.toastStatus
  );

  const showToastVehicleName = useSelector(
    (state) => state.toast_messages.toastVehicleName
  );

  const showToasts = useSelector(
    (state) => state.toast_messages.isToastVisible
  );

  const messageToastsHandler = () => {
    dispatch(setShowToasts({ show: false }));
    dispatch(setShowBlueFlag({ flagColor: false }));
  };

  return (
    <>
      <div>
        <HeaderMaintable />
      </div>

      <div>
        <div className="flex place-items-center text-left font-bold text-3xl px-20  bg-white">
          <div className="w-full">
            <SearchBar isMainVehicleTable={false} />
          </div>
        </div>
      </div>
      <div className="px-20 py-5 bg-white">
        <VehicleList isMainVehicleTable={false} />
      </div>
      <div className="bg-white px-10">
        <MainTableFooter />
      </div>
      {showToasts && (
        <div className="fixed right-5 bottom-5 box-border h-16 w-96 border-2 pl-2 border-transparent bg-toastsBackgroundColor rounded-md">
          <div className="flex items-center relative text-policaroBlue box-border h-full">
            <div className=" items-center px-2">
              <label>{showToastStatus} </label>
            </div>
            <div className="items-center h-full flex overflow-auto pr-2 w-60">
              <label className="font-bold">{showToastVehicleName} </label>
            </div>
            <div className="absolute right-3">
              <XIcon
                className="h-7 w-7 text-policaroBlue cursor-pointer"
                aria-hidden="true"
                onClick={messageToastsHandler} // removing the toasts message
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
