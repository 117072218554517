import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as NewPolicaroLogo } from "../component/policaro-logo-image/policaroLogo.svg";

export default function VerifyUserSuccessfully() {
  let history = useHistory();

  return (
    <>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <div>
              <NewPolicaroLogo className="w-36" />
            </div>
          </div>
          <h2 className="mt-6 text-center text-2xl font-bold text-gray-900">
            Inventory Portal
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="grid grid-cols-10 mb-5">
                <div className="col-span-9">
                  <h3 className="text-left text-xl font-sans leading-6 font-medium text-textPrimary">
                    Verify Account
                  </h3>
                </div>
              </div>
              <div className="mt-5">
                <div className="rounded-md bg-sentRecoveryEmailBg py-5 sm:flex sm:items-start sm:justify-between">
                  <div className="text-governor-bay text-left mt-3 sm:mt-0 sm:ml-4 font-normal">
                    Your account has been verified successfully
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="mt-5 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-sans text-white bg-policaroBlue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Return to Log In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
