import { Fragment } from "react";
import { Popover } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import { ReactComponent as NewPolicaroLogo } from "../component/policaro-logo-image/policaroLogo.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLoginActions } from "../store/login-slice";
import { mainTableSliceActions } from "../store/main-table-slice";
import { logoutCurrentUser } from "../store/logout-actions";
import { useCookies } from "react-cookie";

export default function HeaderMaintable() {
  // eslint-disable-next-line no-unused-vars
  const [cookies, removeCookie] = useCookies(["authentication"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const FORCE_CHANGE_PASSWORD = useSelector(
    (state) => state.login.force_change_password_flag
  );

  const logOutHandler = () => {
    // clears the local storage and logs out the user
    dispatch(logoutCurrentUser());

    if (FORCE_CHANGE_PASSWORD) {
      dispatch(
        userLoginActions.setChangePasswordFlag({
          flagState: false,
        })
      );
    }

    history.push("./"); // go back to login
  };

  // getting the user email and displaying it to the header
  const sessionUser = localStorage.getItem("EMAIL");
  return (
    <>
      <div className="border border-gray-200 border-opacity-100 ">
        <Popover className="relative bg-white">
          <div className="flex justify-between items-center sm:px-6 md:justify-start md:space-x-10">
            <div className="px-4 py-6">
              <a href="#" className="flex">
                <span className="sr-only">Workflow</span>
                <NewPolicaroLogo className="w-48" />
                <label className="sm:px-3 text-bold text-2xl">
                  Inventory&nbsp;Portal{" "}
                </label>
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
              <Popover.Group as="nav" className="flex space-x-10">
                <div
                  className={`${
                    location.pathname == "/main-vehicle-table"
                      ? "nav-option border-b-4 border-policaroBlue"
                      : "nav-option"
                  }`}
                >
                  <a
                    onClick={() => {
                      // To determine what table is open. Here, its inventory table
                      dispatch(
                        mainTableSliceActions.determineWhatTableIsOpen({
                          isMain: true,
                        })
                      );
                      dispatch(mainTableSliceActions.resetPagination());
                      history.push("/main-vehicle-table");
                    }}
                    className="cursor-pointer text-lg font-sans text-gray-500 hover:text-gray-900"
                  >
                    Inventory
                  </a>
                </div>
                <div
                  className={`${
                    location.pathname == "/sold-archive"
                      ? "nav-option border-b-4  border-policaroBlue"
                      : "nav-option"
                  }`}
                >
                  <a
                    className="border-b-black border border-black"
                    onClick={() => {
                      // To determine what table is open. Here, its inventory table
                      dispatch(
                        mainTableSliceActions.determineWhatTableIsOpen({
                          isMain: false,
                        })
                      );
                      dispatch(mainTableSliceActions.resetPagination());
                      history.push("/sold-archive");
                    }}
                    className="cursor-pointer text-lg font-sans text-gray-500 hover:text-gray-900"
                  >
                    Sold&nbsp;Archive
                  </a>
                </div>
              </Popover.Group>
            </div>
            <div className="flex items-center md:ml-12">
              <a
                href="#"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                <div className="content-center inline-flex items-center justify-center">
                  <div className="mx-1 ml-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="cursor-default">{sessionUser}</div>
                </div>
              </a>
              <a
                href="#"
                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-policaroBlue hover:bg-indigo-700"
                onClick={logOutHandler}
              >
                Log&nbsp;out
              </a>
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
}
