import { createSlice } from "@reduxjs/toolkit";

const searchBarSlice = createSlice({
  name: "search-bar",
  initialState: {
    search_value: "",
  },
  reducers: {
    searchBarValue: (state, action) => {
      state.search_value = action.payload.search;
    },
  },
});

export const searchBarReducers = searchBarSlice.actions;
export default searchBarSlice;
