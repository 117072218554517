import React from "react";
import { XIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as NewPolicaroLogo } from "../component/policaro-logo-image/policaroLogo.svg";

export default function SentRecoveryEmail() {
  let history = useHistory();

  let destinationEmail = useSelector(
    (state) => state.send_recovery.destination_email
  );

  const exitButtonHandler = () => {
    history.push("/");
  };
  return (
    <>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <div>
              <NewPolicaroLogo className="w-36" />
            </div>
          </div>
          <h2 className="mt-6 text-center text-2xl font-bold text-gray-900">
            Inventory Portal
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-left text-xl font-sans leading-6 font-medium text-textPrimary">
                    Send Recovery Email
                  </h3>
                </div>
                <div>
                  <XIcon
                    className="w-6 h-6 cursor-pointer"
                    onClick={exitButtonHandler}
                  />
                </div>
              </div>

              <div className="mt-5">
                <div className="rounded-md bg-sentRecoveryEmailBg py-5 sm:flex sm:items-start sm:justify-between">
                  <div className="text-governor-bay text-left text-sm  mt-3 sm:mt-0 sm:mx-4 font-normal">
                    <label className="w-full overflow-x-auto">
                      We'll send a recovery email to
                      <label className="font-bold px-1">
                        {destinationEmail}.
                      </label>
                      It may take a few minutes to arrive.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
