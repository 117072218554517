import { userLoginActions } from "./login-slice";

export const logoutCurrentUser = () => {
  return async (dispatch) => {
    const logOut = async () => {
      const response = await fetch("/auth/logout");
      return await response;
    };

    const responseStatus = await logOut();
    if (responseStatus.status === 200 || responseStatus.status === 401) {
      localStorage.clear();
      dispatch(userLoginActions.setAuth({ auth: false }));
      dispatch(
        userLoginActions.updateErrorLoginFlag({
          loginUser: false,
        })
      );
    }
  };
};
