import React from "react";

export default function InputErrorSection(props) {
  const inputErrors = props.inputErrors;
  const inputField = props.inputField;

  const getErrorMessage = (field) => {
    return inputErrors[field];
  };

  const isInputInvalid = (field) => {
    return field in inputErrors;
  };

  return (
    <div
      className={`${
        isInputInvalid(inputField) == true ? "visible" : "invisible"
      } w-full h-full sm:text-sm text-red-500 text-left`}
    >
      {isInputInvalid(inputField) == true ? getErrorMessage(inputField) : null}
    </div>
  );
}
