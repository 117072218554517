import "./App.css";
import Login from "./utils/Login";
import SendRecoveryEmail from "./utils/Send-RecoveryEmail";
import SentRecoveryEmail from "./utils/Sent-RecoveryEmail";
import ResetPassword from "./utils/Reset-Password";
import ResetPasswordSuccessfully from "./utils/Reset-Password-Successfully";
import VerifyUser from "./utils/Verify-User-Account";
import VerifyUserSuccessfully from "./utils/Verify-User-Successfully";
import MainVehicleTable from "./component/Main-Vehicle-Table";
import AddVehicle from "./component/Second-Version-Vehicle-Form/AddVehicle-Form";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import SoldArchive from "./component/SoldArchive";
import ChangeTempPassword from "./utils/Change_Temporary_Password";
function App() {
  // getting the current state from the loginSlice.
  const userAuth = useSelector((state) => state.login.isAuthenticated);

  const editOrAddBtn = useSelector(
    (state) => state.add_vehicle.ADD_VEHICLE_FLAG
  );

  return (
    <div className="App bg-athens-gray-500">
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route
            exact
            path="/main-vehicle-table"
            component={() => <MainVehicleTable authorized={userAuth} />}
          />

          <Route exact path="/sold-archive" component={() => <SoldArchive />} />

          <Route
            exact
            path="/add-vehicle"
            component={() => <AddVehicle isFromAddVehicleBtn={editOrAddBtn} />}
          />

          <Route
            exact
            path="/send-recovery-email"
            component={SendRecoveryEmail}
          />

          <Route
            exact
            path="/sent-recovery-email-successfully"
            component={SentRecoveryEmail}
          />

          <Route exact path="/reset-password" component={ResetPassword} />

          <Route
            exact
            path="/reset-password-successfully"
            component={ResetPasswordSuccessfully}
          />

          <Route exact path="/verify-account" component={VerifyUser} />

          <Route
            exact
            path="/verify-account-successfully"
            component={VerifyUserSuccessfully}
          />

          <Route
            exact
            path="/force_reset_password"
            component={ChangeTempPassword}
          />
          <Route component={Login} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
