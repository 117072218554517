import "./Vehicle-Photo-Upload-Section.css";
import React, { useState, useEffect } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { addVehicleActions } from "../../store/add-vehicle-slice";
import Dropzone from "react-dropzone";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ListManager } from "react-beautiful-dnd-grid";
import Modal from "../Modal-Image";

export default function PhotoUpload(props) {
  const maxSize = 5000000;
  const maxNumImages = 100;
  const { images } = props.parentVehicle;
  const dispatch = useDispatch();

  const [vehicleImageList, setVehicleImageList] = useState(
    images ? images : []
  );

  const [currentSelectedImage, setCurrentSelectedImage] = useState(0);
  const [open, setOpen] = useState(false); // to open the image carousel
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const carouselOpenHandler = (state) => () => {
    setOpen(state);
  };

  const viewCurrentImage = (imageId) => () => {
    let imageIndex = vehicleImageList.findIndex(
      (image) => image.id === imageId
    );
    setCurrentSelectedImage(imageIndex);
    if (open == true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const uploadImageHandler = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      // Creating the multipart form the for the multer to work
      const data = new FormData();
      // Putting all the url files to FormData.
      for (var i = 0; i < acceptedFiles.length; i++) {
        data.append("vehicle_images", acceptedFiles[i]);
      }
      uploadImage(data);
    } else {
      alert("The selected images must each be of size 5MB or less.");
    }
  };

  // Uploading the image to the to S3
  const uploadImage = async (images) => {
    // THIS ADDS A TEMPORARY ITEM TO THE LIST IN ORDER TO DISPLAY LOADING SPINNER {
    setVehicleImageList([
      ...vehicleImageList,
      { id: "loading_image", order: 10000 },
    ]);
    const response = await fetch("/images", {
      method: "POST",
      body: images,
    });

    const returnObject = await response.json();
    let tempImageList = JSON.parse(JSON.stringify(vehicleImageList));
    const tempIndex = tempImageList.findIndex(
      (image) => image.id === "loading_image"
    );
    if (tempIndex > -1) {
      tempImageList.splice(tempIndex, 1);
    }
    // if uploading is success
    if (response.ok) {
      const startingOrder =
        tempImageList.length > 0
          ? tempImageList[tempImageList.length - 1].order
          : 0;
      const imageArray = tempImageList.concat(
        returnObject["image_info"].map((image, index) => ({
          ...image,
          order: startingOrder + index + 1,
        }))
      );
      setVehicleImageList(imageArray);
    } else {
      setVehicleImageList(tempImageList);
      alert(returnObject.message);
    }
  };

  // remove image on the image list
  const removeImageHandler = (imageId) => () => {
    let imageArray = JSON.parse(JSON.stringify(vehicleImageList)); // create a mutable copy of the list
    let imageIndex = imageArray.findIndex((image) => image.id === imageId);
    deleteImageOnS3Table(imageArray[imageIndex]["id"]);
    imageArray.splice(imageIndex, 1);
    setVehicleImageList(imageArray);
  };

  // delete image on s3 table
  const deleteImageOnS3Table = async (id) => {
    const response = await fetch(`/image/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      let errorCdoe = await response.json();
      alert(errorCdoe);
    }
  };

  // Updates the redux state vehicleImageList every a change on state changes
  useEffect(() => {
    dispatch(
      addVehicleActions.Upload_Photos_Reducer({
        vehicle_photos: vehicleImageList,
      })
    );
  }, [vehicleImageList]);

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  pt-10 border-round bg-athens-gray-500">
        <div className="max-w-3xl mx-auto border-2 border-opacity-100 rounded-md bg-white">
          <div className="pt-5 pb-5">
            <div className="sm:col-span-6">
              <div className="mx-5 flex justify-between items-center">
                <div>
                  <span className="text-xl font-medium text-gray-700">
                    Photos
                  </span>
                </div>
                <div>
                  {vehicleImageList.length == 0 ? (
                    <span className="text-md font-medium text-gray-700">
                      50 Maximum
                    </span>
                  ) : (
                    <span className="text-md font-medium text-gray-700">
                      {vehicleImageList.length} / 50
                    </span>
                  )}
                </div>
              </div>

              <div className="mx-5 mt-5 mb-5 image-grid">
                {loadInitialImagesGrid()}
              </div>
              <Dropzone
                onDrop={uploadImageHandler}
                maxFiles={maxNumImages}
                accept="image/jpeg, image/png"
                maxSize={maxSize}
                uploadMultiple={true}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="cursor-pointer mx-5 mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <span className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                          Upload a file
                        </span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          multiple={true}
                          {...getInputProps()}
                        />
                        <span className="pl-1">or drag and drop</span>
                      </div>
                      <p className="text-xs text-gray-500">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        {/* {Modal()} */}
        <Modal
          vehicleImageList={vehicleImageList}
          isOpen={open}
          openHandler={carouselOpenHandler}
          currentSelectedImage={currentSelectedImage}
        />
      </div>
    </>
  );

  function loadInitialImagesGrid() {
    let reorderList = (sourceIndex, destinationIndex) => {
      if (destinationIndex === sourceIndex) {
        return;
      }
      let list = JSON.parse(JSON.stringify(vehicleImageList));
      if (destinationIndex === 0) {
        list[sourceIndex].order = list[0].order - 1;
        setVehicleImageList(sortList(list));
        return;
      }
      if (destinationIndex === list.length - 1) {
        list[sourceIndex].order = list[list.length - 1].order + 1;
        setVehicleImageList(sortList(list));
        return;
      }
      if (destinationIndex < sourceIndex) {
        list[sourceIndex].order =
          (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
        setVehicleImageList(sortList(list));
        return;
      }
      list[sourceIndex].order =
        (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
      setVehicleImageList(sortList(list));
    };

    let sortList = (list) => {
      return list.slice().sort((first, second) => first.order - second.order);
    };

    return (
      <div>
        <ListManager
          items={vehicleImageList}
          direction="horizontal"
          maxItems={3}
          render={(item) => <ListElement item={item} />}
          onDragEnd={reorderList}
        />
      </div>
    );
  }

  function ListElement({ item: { id, url } }) {
    return url ? (
      <div className="relative image-grid-item aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
        <div onClick={viewCurrentImage(id)}>
          <img
            src={url}
            className="object-scale-down pointer-events-none group-hover:opacity-75 h-36 w-full"
          />
        </div>
        <button
          onClick={removeImageHandler(id)}
          className="h-7 px-1 py-1 bg-white text-policaroBlue absolute top-0 right-0 rounded-md"
        >
          <TrashIcon className="h-7 px-1 py-1 bg-white text-policaroBlue absolute top-0 right-0 rounded-md" />
        </button>
      </div>
    ) : (
      loadingSpinner()
    );
  }

  function loadingSpinner() {
    return (
      <div className="pointer-events-none w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
        <div className="relative">
          <div className="w-full h-full flex justify-center items-center">
            <div className="w-36 h-36  flex justify-center items-center">
              {/* <div className="absolute top-5 w-16 h-16 border-8 border-policaroBlue border-dashed rounded-full animate-spin"></div> */}
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin absolute top-10"
              >
                <circle
                  cx="20"
                  cy="20"
                  r="17.5"
                  stroke="#17479E"
                  strokeWidth="5"
                  strokeDasharray="50 20"
                />
              </svg>
            </div>
            <div className="absolute bottom-5">
              <p className="text-policaroBlue">Uploading</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
