import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { XIcon, CheckIcon } from "@heroicons/react/outline";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { StarIcon } from "@heroicons/react/solid";
import { Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/solid";

import { refreshTable } from "../../store/vehicle-list-slice";
import {
  vehicleList_StarVehicle,
  vehicleList_UnStarVehicle,
} from "../../store/vehicle-list-item-action";

import SideVehiclePanel from "./Vehicle-Side-Panel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function VehicleListItem({
  vehicle,
  index,
  isMainTable,
  TableSortOrder,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const blueFlagState = useSelector(
    (state) => state.toast_messages.lastVehicleBlueBackgroundFlag
  );

  const last_added_vehicle_id = useSelector(
    (state) => state.toast_messages.lastVehicleAddedId
  );

  const mainVehicles = useSelector((state) => state.vehicle_list.vehicles);
  const starredVehicles = useSelector((state) =>
    state.vehicle_list.vehicles.filter(
      (vehicle) => vehicle.sold_sort_order != null
    )
  );

  const [open, setOpen] = useState(false);
  const tableState = useSelector((state) => state.vehicle_list.REFRESH_TABLE);
  let rowClassName =
    "px-6 py-4 text-left whitespace-nowrap text-sm text-vehicleTableFontColor";

  const currentRowHandler = () => () => {
    setOpen(true);
  };

  const setOpenHandler = (state) => () => {
    setOpen(state);
  };

  const starIconOnClickHandler = (id, sortOrder, year, make, model) => () => {
    sortOrder == null
      ? dispatch(vehicleList_StarVehicle(id, year, make, model))
      : dispatch(vehicleList_UnStarVehicle(id, year, make, model));

    tableState == true
      ? dispatch(refreshTable({ forceUpdate: false }))
      : dispatch(refreshTable({ forceUpdate: true }));
  };

  const moveVehicleUp = (sourceId) => () => {
    let vehicleList = mainVehicles;
    if (location.pathname == "/sold-archive") {
      vehicleList = starredVehicles;
    }
    const topId = vehicleList[0].id;
    TableSortOrder(Number(sourceId), Number(topId));
  };

  const moveVehicleDown = (sourceId) => () => {
    let vehicleList = mainVehicles;
    if (location.pathname == "/sold-archive") {
      vehicleList = starredVehicles;
    }
    const vehicleLength = vehicleList.length;
    const bottomMost = vehicleList[vehicleLength - 1].id;
    TableSortOrder(Number(sourceId), Number(bottomMost));
  };

  return (
    <>
      {isMainTable == true ? (
        <td
          key={index}
          className="pl-6 py-4 text-left whitespace-nowrap text-sm text-gray-500"
        >
          <div className="flex items-center justify-start">
            <div>{vehicle.id}</div>
            <div className="px-2">{moveVehicle(vehicle.id)}</div>
          </div>
        </td>
      ) : (
        <td
          key={index}
          className="pl-6 py-4 text-left whitespace-nowrap text-sm text-gray-500"
        >
          <div className="flex items-center text-left justify-start w-full">
            {vehicle.sold_sort_order == null ? (
              <>
                <div className="flex sm:text-left md:text-center lg:text-center">
                  <div className="w-full">
                    <button
                      onClick={starIconOnClickHandler(
                        vehicle.id,
                        vehicle.sold_sort_order,
                        vehicle.year,
                        vehicle.make,
                        vehicle.model
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-left h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex sm:text-left md:text-left lg:text-left">
                  <div className="w-full">
                    <button
                      onClick={starIconOnClickHandler(
                        vehicle.id,
                        vehicle.sold_sort_order,
                        vehicle.year,
                        vehicle.make,
                        vehicle.model
                      )}
                    >
                      <StarIcon className="text-left h-5 w-5 text-yellow-500" />
                    </button>
                  </div>
                  <div>{vehicle.id}</div>{" "}
                  <div className="px-2">{moveVehicle(vehicle.id)}</div>
                </div>
              </>
            )}{" "}
          </div>
        </td>
      )}

      <td className={rowClassName} onClick={currentRowHandler(vehicle.id)}>
        {vehicle.year}
      </td>
      <td className={rowClassName} onClick={currentRowHandler(vehicle.id)}>
        {vehicle.make}
      </td>
      <td className={rowClassName} onClick={currentRowHandler(vehicle.id)}>
        {vehicle.model}
      </td>
      <td className={rowClassName} onClick={currentRowHandler(vehicle.id)}>
        {" "}
        {vehicle.stockNumber}
      </td>
      <td className={rowClassName} onClick={currentRowHandler(vehicle.id)}>
        {" "}
        {vehicle.vin}
      </td>
      <td className={rowClassName} onClick={currentRowHandler(vehicle.id)}>
        {" "}
        {vehicle.dealer_name}
      </td>
      <td className={rowClassName} onClick={currentRowHandler(vehicle.id)}>
        {" "}
        {vehicle.price}{" "}
        <label className="text-grey1"> {vehicle.currency} </label>
      </td>
      <td className={rowClassName} onClick={currentRowHandler(vehicle.id)}>
        {vehicle.included == true ? (
          <div className="flex items-center justify-start">
            <CheckIcon className="h-6 w-6 text-policaroBlue" />
            <div>
              <label className="px-1 text-policaroBlue"> Included </label>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-start">
            <XIcon className="h-6 w-6 text-excludedIconColor" />
            <div>
              <label className="text-secondaryText"> Excluded </label>
            </div>
          </div>
        )}
      </td>

      {/* {SideVehiclePanel(vehicle, open)} */}
      <SideVehiclePanel
        vehicleParamObject={vehicle}
        showPanelParam={open}
        rowClickHandler={setOpenHandler}
      />
    </>
  );

  // Drop down to ask if vehicle move up or down
  function moveVehicle(sourceId) {
    return (
      <Menu
        as="div"
        className={
          isMainTable
            ? blueFlagState == true && vehicle.id == last_added_vehicle_id
              ? "bg-mainTableNewVehicleAddedBackgroundColor"
              : index % 2 == 0
              ? "bg-white"
              : "bg-gray-50"
            : vehicle.sold_sort_order == null
            ? blueFlagState == true && vehicle.id == last_added_vehicle_id
              ? "bg-mainTableNewVehicleAddedBackgroundColor"
              : index % 2 == 0
              ? "bg-gray-50"
              : "bg-white"
            : "bg-starredVehicleRowBackground inline-block text-left"
        }
      >
        <div>
          <Menu.Button className="text-black flex items-center hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-10 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={moveVehicleUp(sourceId)}
                  >
                    <div className="flex flex-row">
                      <div className="pr-1">
                        <ArrowUpIcon className="h-5 w-5" />
                      </div>
                      <div>Move to top</div>
                    </div>
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={moveVehicleDown(sourceId)}
                  >
                    <div className="flex flex-row">
                      <div className="pr-1">
                        <ArrowDownIcon className="h-5 w-5" />
                      </div>
                      <div>Move to bottom</div>
                    </div>
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
}
