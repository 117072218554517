import { userLoginActions } from "./login-slice";

export const loginUserInfo = (paramEmail, paramPassword, paramTempPassword) => {
  return async (dispatch) => {
    // To show the proper user error feedback
    const UserErrorFeedback = (code) => {
      dispatch(
        userLoginActions.saveErrorCode({
          response_code: code,
        })
      );
    };

    // true - user logs in, false - user logs out
    const SetErrorLoginFlag = (loginFlag) => {
      dispatch(
        userLoginActions.updateErrorLoginFlag({
          loginUser: loginFlag,
        })
      );
    };

    // true - redirects to new password screen
    const SetChangePassWordFlag = (changePasswordFlag) => {
      dispatch(
        userLoginActions.setChangePasswordFlag({
          flagState: changePasswordFlag,
        })
      );
    };

    // query GET /auth/user to check if this user have privileges
    const CheckIfUserHasPrivileges = async () => {
      const response = await fetch("/auth/user");
      if (!response.ok) {
        alert("Error happened on checking user privilege");
        return;
      }
      return await response.json();
    };

    const loginCurrentUser = async (
      requestEmail,
      requestPassword,
      requestTempPassword
    ) => {
      const LOGIN_URL = "/auth/user";

      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: { "Content-type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          username: requestEmail,
          password: requestPassword,
          newPassword: requestTempPassword ? requestTempPassword : "",
        }),
      });

      return await response.json();
    };

    try {
      let SUCCESS = 0;
      let FORCE_CHANGE_PASS = 10;

      const returnJson = await loginCurrentUser(
        paramEmail,
        paramPassword,
        paramTempPassword
      );
      if (returnJson.responseCode == SUCCESS) {
        // If Success, check leasing_portal_admin_flag
        console.log(returnJson);
        const checkAdminToken = await CheckIfUserHasPrivileges();
        console.log(checkAdminToken);
        if (checkAdminToken.leasing_portal_admin == true) {
          localStorage.setItem("EMAIL", paramEmail); // this email address will be used on the page header
          localStorage.setItem("TOKEN_KEY", returnJson.message);

          UserErrorFeedback(returnJson.responseCode); // sets it to 0 so no invalid message will show up
          SetErrorLoginFlag(true); // true - user logs in, false - user logs out
        } else {
          // Will create a proper error message for this soon
          alert("User does not have admin privileges");
        }
      } else {
        // If unauthorized or need to change the temp password
        let returnCode = JSON.parse(returnJson.message); // parsing the error cdoe
        // if code == 10
        if (returnCode.code == FORCE_CHANGE_PASS) {
          SetChangePassWordFlag(true);
        } else {
          UserErrorFeedback(returnJson.code); // sets it to 0 so no invalid message will show up
        }
      }
    } catch (error) {
      alert("Failed on log in user request");
    }
  };
};
