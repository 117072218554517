import React, { useState, useEffect } from "react";
import { XIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendRecoveryEmailAction } from "../store/send-recovery-email-slice";
// eslint-disable-next-line no-unused-vars
import { sendRecoveryEmailActionCreator } from "../store/send-recovery-email-action";
import { ReactComponent as NewPolicaroLogo } from "../component/policaro-logo-image/policaroLogo.svg";

function validateEmail(email) {
  const regexEmailFormat =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regexEmailFormat.test(email);
}
``;

export default function SendRecoveryEmail() {
  let INVALID_EMAIL_FLAG = 1;
  let history = useHistory();
  let dispatch = useDispatch();

  const validState = "pl-3 border rounded w-full h-10";
  const invalidState =
    "rounded-md placeholder-black-300 pl-3 border-2 border-opacity-100 h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-red-500";

  let inputClassName = validState;
  const ERROR_CODE = useSelector((state) => state.send_recovery.error_code);

  const SUCCESSFUL_FLAG = useSelector(
    (state) => state.send_recovery.email_send_successfully
  );

  const [recovery_email, SetRecoveryEmailAddress] = useState("");

  const emailInputHandler = (event) => {
    SetRecoveryEmailAddress(event.target.value);
  };

  const SendEmailHandler = (event) => {
    if (recovery_email == "") {
      dispatch(
        sendRecoveryEmailAction.saveErrorSendRecoveryCode({
          response_code: INVALID_EMAIL_FLAG,
        })
      );
    } else {
      let isValid = validateEmail(recovery_email);
      if (isValid) {
        dispatch(sendRecoveryEmailActionCreator(recovery_email));

        // saving the current dest email so it can be use by Sent-Recovery-Email
        dispatch(
          sendRecoveryEmailAction.sendRecoveryEmail({
            dest_email: recovery_email,
          })
        );
        // removing the invalid feedback
        dispatch(
          sendRecoveryEmailAction.saveErrorSendRecoveryCode({
            response_code: 0,
          })
        );
      } else {
        // error message
        dispatch(
          sendRecoveryEmailAction.saveErrorSendRecoveryCode({
            response_code: INVALID_EMAIL_FLAG,
          })
        );
      }
    }
    event.preventDefault();
  };

  useEffect(() => {
    if (SUCCESSFUL_FLAG == true) {
      history.push("/sent-recovery-email-successfully");
    }
  }, [SUCCESSFUL_FLAG]);

  const promptErrorMessage = (code) => {
    let SUCCESS = 0;

    if (code == SUCCESS) {
      return "";
    } else {
      return (
        <div className="mt-5">
          <div className="rounded-md bg-sendRecoveryErrorBg py-5 sm:flex sm:items-start sm:justify-between">
            <div className="text-sendRecoveryErrorFontColor text-left mt-3 sm:mt-0 sm:ml-4 font-normal">
              Please enter a valid email address
            </div>
          </div>
        </div>
      );
    }
  };

  {
    ERROR_CODE == 0
      ? (inputClassName = validState)
      : (inputClassName = invalidState);
  }

  return (
    <>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <div>
              <NewPolicaroLogo className="w-40" />
            </div>
          </div>
          <h2 className="mt-6 text-center text-2xl font-bold text-gray-900">
            Inventory Portal
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-2 shadow sm:rounded-lg sm:pt-6">
            <div className="text-left px-4">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-left text-xl font-sans leading-6 font-medium text-textPrimary">
                    Send Recovery Email
                  </h3>
                </div>
                <div>
                  <XIcon
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => history.push("./")}
                  />
                </div>
              </div>

              <div className="mt-5 max-w-xl text-sm text-textPrimary">
                <p>
                  We'll send you an email with a link to reset your password.
                </p>
              </div>
              <div className="my-5">
                <form className="w-full" onSubmit={SendEmailHandler}>
                  <label className="text-md font-sans text-sm">
                    Email Address
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={recovery_email}
                    className={inputClassName}
                    onChange={emailInputHandler}
                  />
                </form>

                {promptErrorMessage(ERROR_CODE)}

                <div>
                  <button
                    type="submit"
                    className="w-full bg-policaroBlue rounded h-12 my-5 text-white font-sans text-sm cursor-pointer"
                    onClick={SendEmailHandler}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
