import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../store/vehicle-list-slice";
import { VehicleListItem } from "./Vehicle-List-Item";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { refreshTable } from "../../store/vehicle-list-slice";
import { logoutCurrentUser } from "../../store/logout-actions";
import { useHistory } from "react-router-dom";
import { mainTableSliceActions } from "../../store/main-table-slice";
import { useCookies } from "react-cookie";

export default function VehicleList(props) {
  // eslint-disable-next-line no-unused-vars
  const [cookies, removeCookie] = useCookies(["authentication"]);
  const dispatch = useDispatch();
  let history = useHistory();
  const vehicleListState = useSelector((state) => state.vehicle_list);
  const currentPageNumber = useSelector(
    (state) => state.main_table.currentPageNumber
  );
  const showPerPageValue = useSelector(
    (state) => state.main_table.showPerPageValue
  );
  // eslint-disable-next-line no-unused-vars
  const [loadSidePanel, setLoadSidePanel] = useState(true);
  // access the value of the redux state
  const searchBarValue = useSelector((state) => state.search_bar.search_value);

  const forceUpdateTable = useSelector(
    (state) => state.vehicle_list.REFRESH_TABLE
  );

  const blueFlagState = useSelector(
    (state) => state.toast_messages.lastVehicleBlueBackgroundFlag
  );

  const last_added_vehicle_id = useSelector(
    (state) => state.toast_messages.lastVehicleAddedId
  );

  let colHeaderClassname =
    "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider";

  const logOutHandler = () => {
    dispatch(logoutCurrentUser());
    history.push("./"); // go back to login
  };

  // Side effect to update the vehicle table
  useEffect(() => {
    let url = "";

    let urlSearchVehicleMainTable = `/search/vehicle?q=${encodeURIComponent(
      searchBarValue
    )}`; // search route
    let urlSearchVehicleSoldTable = `/search/vehicle?q=${encodeURIComponent(
      searchBarValue
    )}&sold=true`; // search route

    let urlMainTable = `/vehicle?page=${currentPageNumber}&count=${showPerPageValue}`;
    let urlSoldTable = `/vehicle?page=${currentPageNumber}&count=${showPerPageValue}&sold=true`;

    searchBarValue.length <= 3
      ? props.isMainVehicleTable == true
        ? (url = urlMainTable)
        : (url = urlSoldTable)
      : props.isMainVehicleTable == true
      ? (url = urlSearchVehicleMainTable)
      : (url = urlSearchVehicleSoldTable);

    const fetchPage = async () => {
      const response = await fetch(url);
      dispatch(
        mainTableSliceActions.setTotalVehicles({
          totalVehicles: response.headers.get("X-ROW-COUNT"),
        })
      );
      if (response.ok) {
        const bodyJson = await response.json();
        dispatch(
          changePage({
            vehicles: bodyJson,
          })
        );
      } else {
        if (response.status === 401) {
          logOutHandler(); // Logout user when token timedout
        }
      }
    };
    fetchPage(0);
  }, [forceUpdateTable, searchBarValue, currentPageNumber, showPerPageValue]);

  // Accepts id of source and destination to reorder vehicles
  const SortTable = async (source, destination) => {
    let mainTableSortingUrl = `/vehicle/main/arrangement?above=${source}&below=${destination}`;
    let soldTableSortingUrl = `/vehicle/sold/arrangement?above=${source}&below=${destination}`;
    let url = "";

    // check to see if the sorting is being done on main table or sold vehicle table
    props.isMainVehicleTable == true
      ? (url = mainTableSortingUrl)
      : (url = soldTableSortingUrl);

    const response = await fetch(url, {
      method: "PUT",
    });

    if (!response.ok) {
      let errorCode = await response.json();
      alert(errorCode["message"]);
    }

    forceUpdateTable == true
      ? dispatch(refreshTable({ forceUpdate: false }))
      : dispatch(refreshTable({ forceUpdate: true }));
  };

  // handles the user interaction on the drag and drop and query the backend for sorting
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    SortTable(
      vehicleListState.vehicles[source.index].id,
      vehicleListState.vehicles[destination.index].id
    );
  };

  if (vehicleListState.vehicles != null && vehicleListState.vehicles != 0) {
    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {props.isMainVehicleTable ? (
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        #
                      </th>
                    ) : (
                      <th className="px-12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        #
                      </th>
                    )}

                    <th className={colHeaderClassname}>Year</th>
                    <th className={colHeaderClassname}>Make</th>
                    <th className={colHeaderClassname}>Model</th>
                    <th className={colHeaderClassname}>Stock #</th>
                    <th className={colHeaderClassname}>VIN</th>
                    <th className={colHeaderClassname}>Dealer</th>
                    <th className={colHeaderClassname}>Price</th>
                    <th className={colHeaderClassname}>On Leasing Site</th>
                  </tr>
                </thead>

                {searchBarValue.length == 0 ? (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="table" direction="vertical">
                      {(provided) => (
                        <tbody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {vehicleListState.vehicles.map(
                            (vehicle, vehicleIdx) => {
                              return (
                                <Draggable
                                  draggableId={vehicle.id.toString()}
                                  key={vehicle.id.toString()}
                                  index={vehicleIdx}
                                >
                                  {(provided) => {
                                    return (
                                      <tr
                                        key={vehicleIdx}
                                        className={
                                          // if mainTable = true, vehicleid == lastVehicleAddedId, blueFlag == true, then bg to blue
                                          props.isMainVehicleTable
                                            ? blueFlagState == true &&
                                              vehicle.id ==
                                                last_added_vehicle_id
                                              ? "border-l-8 border-policaroBlue bg-mainTableNewVehicleAddedBackgroundColor"
                                              : vehicleIdx % 2 === 0
                                              ? "bg-white"
                                              : "bg-gray-50"
                                            : vehicle.sold_sort_order == null
                                            ? blueFlagState == true &&
                                              vehicle.id ==
                                                last_added_vehicle_id
                                              ? "border-l-8 border-policaroBlue bg-mainTableNewVehicleAddedBackgroundColor"
                                              : vehicleIdx % 2 === 0
                                              ? "bg-white"
                                              : "bg-gray-50"
                                            : "bg-starredVehicleRowBackground"
                                        }
                                        {...provided.droppableId}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <VehicleListItem
                                          key={vehicle.id}
                                          TableSortOrder={SortTable}
                                          vehicle={vehicle}
                                          index={vehicleIdx}
                                          isMainTable={props.isMainVehicleTable}
                                          isLoadSidePanel={loadSidePanel} // if the table values are not loaded based on the search bar value
                                        />
                                      </tr>
                                    );
                                  }}
                                </Draggable>
                              );
                            }
                          )}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  // If search bar is not blank, table is not loaded based on the value from the search bar
                  <tbody>
                    {vehicleListState.vehicles.map((vehicle, vehicleIdx) => {
                      return (
                        <tr
                          key={vehicleIdx}
                          className={
                            props.isMainVehicleTable
                              ? blueFlagState == true &&
                                vehicle.id == last_added_vehicle_id
                                ? "border-l-8 border-policaroBlue bg-mainTableNewVehicleAddedBackgroundColor"
                                : vehicleIdx % 2 === 0
                                ? "bg-white"
                                : "bg-gray-50"
                              : vehicle.sold_sort_order == null
                              ? blueFlagState == true &&
                                vehicle.id == last_added_vehicle_id
                                ? "border-l-8 border-policaroBlue bg-mainTableNewVehicleAddedBackgroundColor"
                                : vehicleIdx % 2 === 0
                                ? "bg-white"
                                : "bg-gray-50"
                              : "bg-starredVehicleRowBackground"
                          }
                        >
                          <VehicleListItem
                            key={vehicle.id}
                            TableSortOrder={SortTable}
                            vehicle={vehicle}
                            index={vehicleIdx}
                            isMainTable={props.isMainVehicleTable}
                            isLoadSidePanel={loadSidePanel}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (
    // empty table
    vehicleListState.vehicles.length == 0 &&
    searchBarValue.length == 0
  ) {
    return (
      <>
        <div className="flex items-center justify-center shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-emptyTableStateBackground h-96">
          <div className="flex-col">
            <div>
              <label> No vehicles in leasing inventory </label>
            </div>
            <div className="py-3">
              <label className="text-policaroBlue">Add Vehicle </label>
            </div>
          </div>
        </div>
      </>
    );
  } else if (
    // search vehicle is not found
    vehicleListState.vehicles.length == 0 &&
    searchBarValue.length != 0
  ) {
    return (
      <>
        <div className="flex items-center justify-center shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-emptyTableStateBackground h-96">
          <div className="flex-col">
            <div>
              <label> No such vehicle was found in leasing inventory </label>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div>Loadin</div>;
  }
}
