import { useCallback, useState } from "react";
import { defaultObjectFormat } from "../model/Vehicle-Info/Vehicle-Info";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addVehicleActions } from "../store/add-vehicle-slice";
import { searchBarReducers } from "../store/search-bar-slice";
import debounce from "lodash.debounce";
import { SearchIcon, XIcon } from "@heroicons/react/outline";

export default function SearchBar({ isMainVehicleTable }) {
  let history = useHistory();
  const dispatch = useDispatch();
  const vehicleListState = useSelector((state) => state.vehicle_list);
  const searchBarValue = useSelector((state) => state.search_bar.search_value);
  const [searchValue, setSearchBarValue] = useState(
    searchBarValue ? searchBarValue : ""
  );

  const determineWhatTableIsOpen = useSelector(
    (state) => state.main_table.isFromMainTable
  );
  const debounceQuery = useCallback(
    debounce(
      (e) =>
        dispatch(
          searchBarReducers.searchBarValue({
            search: e,
          })
        ),
      500
    )
  );
  const searchBarOnChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
    debounceQuery(event.target.value);
  };

  const clearSearchBarOnChangeHandler = () => {
    setSearchBarValue("");
    dispatch(
      searchBarReducers.searchBarValue({
        search: "",
      })
    );
  };

  return (
    <>
      <div>
        <label
          htmlFor="email"
          className="block text-3xl my-5 font-medium text-gray-700"
        >
          {determineWhatTableIsOpen ? "Inventory" : "Sold Archive"}
        </label>
        <div className="flex justify-between h-ful w-full items-center">
          <div className="flex text-base border border-opacity-100 border-textInputBorder w-2/3 h-12 rounded-md px-5 items-center justify-between">
            <div className="flex w-2/3 items-center justify-center">
              <SearchIcon className="h-5 w-6 mr-2" />
              <input
                type="text"
                className="w-full focus:outline-none focus:ring-none focus:border-none"
                placeholder="Search by year, make, model, stock #, VIN or dealer"
                value={searchValue}
                onChange={searchBarOnChangeHandler}
              />
            </div>

            <div className="flex justify-end items-center">
              <button onClick={clearSearchBarOnChangeHandler}>
                <XIcon className="h-6 w-6" />
              </button>
            </div>
          </div>

          {isMainVehicleTable == true && (
            <div>
              <button
                type="button"
                className="w-full h-12 px-3 inline-flex justify-center items-center rounded-md border border-transparent shadow-sm bg-policaroBlue font-sans text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  dispatch(
                    addVehicleActions.EditOrAddFlag({ current_flag: true })
                  );
                  history.push({
                    pathname: "./add-vehicle",
                    state: {
                      vehicle_information: defaultObjectFormat, // Pass the empty Vehicle object to initialize format
                      isFromAddVehicleBtn: true,
                    },
                  });
                }}
              >
                <p className="text-base"> + Add Vehicle </p>
              </button>
            </div>
          )}
        </div>
        {/* Display number of vehicles */}
        <label className="text-sm font-sans font-normal text-grey2">
          <label className="font-bold">
            {vehicleListState.vehicles.length}{" "}
          </label>{" "}
          vehicles
        </label>
      </div>
    </>
  );
}
