import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function DiscardChangesModal({
  discardModalState,
  discardModalHandler,
}) {
  const determineWhatTableIsOpen = useSelector(
    (state) => state.main_table.isFromMainTable
  );

  let history = useHistory();

  const discardVehicleFormChanges = () => {
    // close the modal and go back to vehicle table
    discardModalHandler(false); // closing the modal
    determineWhatTableIsOpen // if main table is open, go back to main. If not go back to sold
      ? history.push("/main-vehicle-table")
      : history.push("/sold-archive");
  };
  return (
    <Transition.Root show={discardModalState} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={discardModalState}
        onClose={discardModalHandler}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="border-b-2 border-textInputBorder bg-textInputBackground px-4 py-3 sm:px-6 sm:flex sm:flex-row">
                <label className="text-lg">Discard Changes</label>

                <div className="content-end absolute right-0 top-2.5">
                  <button
                    type="button"
                    className="content-center inline-flex items-center p-1.5 text-black rounded-full "
                    onClick={discardModalHandler(false)}
                  >
                    <XIcon className="content-center w-5 h-5 mr-5" />
                  </button>
                </div>
              </div>

              <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                    <div>
                      <p className="text-sm text-black font-sans ">
                        Do you want to discard all the changes in the vehicle?
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-t-2 mt-3 border-textInputBorder bg-textInputBackground px-2 py-3 sm:px-2 sm:flex sm:flex-row">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-removeButtonBackground text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={discardVehicleFormChanges}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-textPrimary hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={discardModalHandler(false)}
                >
                  No
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
