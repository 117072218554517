// Default table values from POLICARO Group
export const tableDataSuperCar = {
  5000: {
    12: 75,
    18: 70,
    24: 65,
    30: 60,
    36: 55,
    42: 50,
    48: 45,
  },

  10000: {
    12: 72,
    18: 67,
    24: 62,
    30: 57,
    36: 52,
    42: 47,
    48: 42,
  },
};

export const tableDataMainLine = {
  15000: {
    12: 67,
    18: 63,
    24: 59,
    30: 55,
    36: 51,
    42: 47,
    48: 43,
  },

  20000: {
    12: 64,
    18: 60,
    24: 56,
    30: 52,
    36: 48,
    42: 44,
    48: 40,
  },

  25000: {
    12: 61,
    18: 57,
    24: 53,
    30: 49,
    36: 42,
    42: 38,
    48: 34,
  },

  30000: {
    12: 58,
    18: 54,
    24: 50,
    30: 46,
    36: 42,
    42: 38,
    48: 34,
  },
};

export const tableDataHighline = {
  5000: {
    12: 77,
    18: 73,
    24: 69,
    30: 65,
    36: 61,
    42: 57,
    48: 53,
  },

  10000: {
    12: 74,
    18: 70,
    24: 66,
    30: 61,
    36: 58,
    42: 54,
    48: 50,
  },

  15000: {
    12: 71,
    18: 67,
    24: 63,
    30: 59,
    36: 55,
    42: 51,
    48: 47,
  },

  20000: {
    12: 68,
    18: 64,
    24: 60,
    30: 56,
    36: 52,
    42: 48,
    48: 44,
  },

  25000: {
    12: 65,
    18: 61,
    24: 57,
    30: 53,
    36: 49,
    42: 45,
    48: 41,
  },
};
