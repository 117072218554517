import { resetAccountPassword } from "./reset-password-slice";

export const resetUserAccountPasswordAction = (email, code, newPassword) => {
  return async (dispatch) => {
    const setErrorResponseCode = (errorCode) => {
      dispatch(
        resetAccountPassword.saveQueryErrorResponseCode({ code: errorCode })
      );
    };

    const isPasswordChangedSuccessfully = (flagState) => {
      dispatch(
        resetAccountPassword.passwordChangeSuccessfully({
          passwordChangeFlag: flagState,
        })
      );
    };

    const resetPassword = async (email, code, newPassword) => {
      const response = await fetch("/auth/resetPassword", {
        method: "POST",
        headers: { "Content-type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          username: email,
          verificationCode: code,
          password: newPassword,
        }),
      });

      if (!response.ok) {
        const errorCode = await response.json();
        console.log(errorCode.message.code);
        return errorCode;
      }

      return await response.json();
    };

    try {
      const resetPassResponseCode = await resetPassword(
        email,
        code,
        newPassword
      );

      console.log(resetPassResponseCode);
      console.log(resetPassResponseCode.responseCode);
      console.log(resetPassResponseCode.message.code);

      if (resetPassResponseCode.responseCode != 0) {
        let errorMessage = JSON.parse(resetPassResponseCode.message);
        alert(errorMessage.code);

        // dont change change screens
        isPasswordChangedSuccessfully(false);

        // display error message
        setErrorResponseCode(errorMessage.code);
      } else {
        // change screens
        isPasswordChangedSuccessfully(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
};
