import {
  setToastInformation,
  setNewVehicleTableBackgroundToBlue,
} from "./toast-message-slice";
export const AddVehicleInfoActionCreator = (VehicleDataParam) => {
  let addVehicleDatabaseUrl = "/vehicle";
  return async (dispatch) => {
    // Converts the residual table that the database can read
    const formatResidualTable = (vehicleResidual) => {
      let table = [];
      if (vehicleResidual == null) {
        return table;
      }
      try {
        Object.keys(vehicleResidual).map((row) =>
          Object.keys(vehicleResidual[row]).map((column) => {
            table.push({
              term: column,
              distance: row,
              value: vehicleResidual[row][column],
            });
          })
        );
        return table;
      } catch {
        return vehicleResidual;
      }
    };

    const formatImages = (vehicleImages) => {
      let tableImages = [];
      if (vehicleImages == null) {
        return tableImages;
      }

      try {
        Object.keys(vehicleImages).map((row, rowIndex) => {
          tableImages.push({
            url: vehicleImages[rowIndex],
          });
        });
      } catch {
        return vehicleImages;
      }
      return tableImages;
    };

    // when the image is already uploaded in S3,its now time to assign those images to the vehicles.

    const AssignImagesToVehicle = async (image_assignments) => {
      const response = await fetch("/imageassignments", {
        method: "PUT",
        headers: { "Content-type": "application/json; charset=utf-8" },
        body: JSON.stringify(image_assignments),
      });

      if (!response.ok) {
        console.log(response.json());
      } else {
        console.log("Success");
      }
    };

    const ApplyImageSortOrder = async (vehicle_image_array) => {
      const imageArray = vehicle_image_array.map((image, index) => ({
        id: image.id,
        url: image.url,
        creation_date: image.creation_date,
        order: index,
      }));

      const response = await fetch("/image/displayorder", {
        method: "PUT",
        headers: { "Content-type": "application/json; charset=utf-8" },
        body: JSON.stringify(imageArray),
      });

      if (!response.ok) {
        console.log(response.json());
      } else {
        console.log("Success");
      }
    };

    const AddVehicleToDB = async (VehicleDataParam) => {
      const response = await fetch(addVehicleDatabaseUrl, {
        method: "PUT",
        headers: { "Content-type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          year: VehicleDataParam.year,
          make: VehicleDataParam.make,
          model: VehicleDataParam.model,
          odometer: VehicleDataParam.odometer,
          odometerUnit: VehicleDataParam.odometerUnit,
          vin: VehicleDataParam.vin,
          stockNumber: VehicleDataParam.stockNumber,
          driveTrain: VehicleDataParam.drivetrain,
          transmission: VehicleDataParam.transmission,
          exteriorColor: VehicleDataParam.exteriorColor,
          interiorColor: VehicleDataParam.interiorColor,
          trim: VehicleDataParam.trim,
          description: VehicleDataParam.description,
          price: VehicleDataParam.price,
          availability: VehicleDataParam.availability,
          included: VehicleDataParam.includeOnLeasing,
          currency: VehicleDataParam.currency,
          interest_rate: VehicleDataParam.interest_rate
            ? VehicleDataParam.interest_rate
            : null,
          product_category: VehicleDataParam.product_category,
          dealer_name: VehicleDataParam.dealer_name,
          residuals: formatResidualTable(VehicleDataParam.residuals),
          images: formatImages(VehicleDataParam.images),
        }),
      });

      if (response.ok) {
        // Setting the toast message
        dispatch(
          setToastInformation({
            status: "Added",
            name: `${VehicleDataParam.year} ${VehicleDataParam.make} ${VehicleDataParam.model}`,
            showToast: true,
          })
        );
      }

      return await response.json();
    };

    const EditAndSaveVehicleToDB = async (VehicleDataParam) => {
      const response = await fetch(addVehicleDatabaseUrl, {
        method: "PUT",
        headers: { "Content-type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          id: VehicleDataParam.id,
          year: VehicleDataParam.year,
          make: VehicleDataParam.make,
          model: VehicleDataParam.model,
          odometer: VehicleDataParam.odometer,
          odometerUnit: VehicleDataParam.odometerUnit,
          vin: VehicleDataParam.vin,
          stockNumber: VehicleDataParam.stockNumber,
          driveTrain: VehicleDataParam.drivetrain,
          transmission: VehicleDataParam.transmission,
          exteriorColor: VehicleDataParam.exteriorColor,
          interiorColor: VehicleDataParam.interiorColor,
          trim: VehicleDataParam.trim,
          description: VehicleDataParam.description,
          price: VehicleDataParam.price,
          availability: VehicleDataParam.availability,
          included: VehicleDataParam.includeOnLeasing,
          currency: VehicleDataParam.currency,
          interest_rate: VehicleDataParam.interest_rate
            ? VehicleDataParam.interest_rate
            : null,
          product_category: VehicleDataParam.product_category,
          dealer_name: VehicleDataParam.dealer_name,
          residuals: formatResidualTable(VehicleDataParam.residuals),
          images: formatImages(VehicleDataParam.images),
        }),
      });

      if (response.ok) {
        dispatch(
          setToastInformation({
            status: "Updated",
            name: `${VehicleDataParam.year} ${VehicleDataParam.make} ${VehicleDataParam.model}`,
            showToast: true,
          })
        );
      }

      return await response.json();
    };

    try {
      if (VehicleDataParam.id.length == 0) {
        await ApplyImageSortOrder(VehicleDataParam.images);
        var addVehicleResponse = await AddVehicleToDB(VehicleDataParam);
        if (addVehicleResponse.id) {
          let returnedVehicleId = addVehicleResponse.id;

          dispatch(
            setNewVehicleTableBackgroundToBlue({
              vehicleId: returnedVehicleId,
              flag: true,
            })
          );
          if (VehicleDataParam.images.length != 0) {
            const assignImages = VehicleDataParam.images.map((image) => ({
              vehicle_id: returnedVehicleId,
              image_id: image.id,
            }));
            AssignImagesToVehicle(assignImages);
          }
        } else {
          throw addVehicleResponse;
        }
      } else {
        await ApplyImageSortOrder(VehicleDataParam.images);
        if (VehicleDataParam.images.length != 0) {
          const assignImages = VehicleDataParam.images.map((image) => ({
            vehicle_id: VehicleDataParam.id,
            image_id: image.id,
          }));
          AssignImagesToVehicle(assignImages);
        }

        var editVehicleResponse = await EditAndSaveVehicleToDB(
          VehicleDataParam
        );

        dispatch(
          setNewVehicleTableBackgroundToBlue({
            vehicleId: VehicleDataParam.id,
            flag: true,
          })
        );

        if (editVehicleResponse.code == 1) {
          throw editVehicleResponse;
        }
      }
    } catch (error) {
      return error;
    }
  };
};
