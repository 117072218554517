import { createSlice } from "@reduxjs/toolkit";

const sendEmailRecoverySlice = createSlice({
  name: "send email recovery",
  initialState: {
    destination_email: "",
    error_code: 0,
    email_send_successfully: false,
  },

  reducers: {
    // save the recovery email
    sendRecoveryEmail(state, action) {
      state.destination_email = action.payload.dest_email;
    },

    // Save error message
    saveErrorSendRecoveryCode(state, action) {
      // Because of Redux toolkit, straight assigning the state to a variable is with a value permitted and safe
      state.error_code = action.payload.response_code;
    },

    // Update the email_send_successfully flag
    updateSendSuccessfullyFlag(state) {
      // Because of Redux toolkit, straight assigning the state to a variable is with a value permitted and safe
      state.email_send_successfully = true;
    },
  },
});

// Exporting so the slice can be seen from other files
export const sendRecoveryEmailAction = sendEmailRecoverySlice.actions;
export default sendEmailRecoverySlice;
