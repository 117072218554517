import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addVehicleActions } from "../../store/add-vehicle-slice";
import InputErrorSection from "./Input-Error-Section";
export default function VehicleInformation(props) {
  const {
    drivetrain,
    transmission,
    exteriorColor,
    interiorColor,
    trim,
    description,
  } = props.parentVehicle;
  const inputErrors = props.inputErrors;
  const dispatch = useDispatch();
  const readyToSendFlag = useSelector((state) => state.add_vehicle.SEND_FLAG);
  const [vehicleInfoDrivetrain, setVehicleInfo_Drivetrain] = useState(
    drivetrain ? drivetrain : "FWD"
  );

  const [vehicleInfoTransmission, setVehicleInfo_Transmission] = useState(
    transmission ? transmission : "AUTO"
  );

  const [vehicleInfoExteriorColour, setVehicleInfo_ExteriorColour] =
    useState(exteriorColor);
  const [vehicleInfoInteriorColour, setVehicleInfo_InteriorColour] =
    useState(interiorColor);
  const [vehicleInfoTrim, setVehicleInfo_Trim] = useState(trim);
  const [vehicleInfoDescription, setVehicleInfo_Description] =
    useState(description);

  const initialUpdate = useRef(true);

  let inputField_Classname =
    "pl-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full sm:text-sm border-gray-300 rounded-md";

  var isValidInput = (field) => {
    if (field in inputErrors) {
      return "mt-1 border-2 border-opacity-50 border-red-500 rounded-md h-10";
    } else {
      return "mt-1 border-2 border-opacity-50 border-textInputBorder rounded-md h-10";
    }
  };

  var descClassValid = () => {
    if ("description" in inputErrors) {
      return "pl-3 pt-2 border rounded-md w-full border-red-500";
    } else {
      return "pl-3 pt-2 border border-gray-300 rounded-md w-full";
    }
  };

  useEffect(() => {
    if (initialUpdate.current) {
      initialUpdate.current = false;
      // Initializing the redux states
      dispatch(
        addVehicleActions.Vehicle_Details_Reducer({
          type: "DRIVETRAIN",
          vehicle_drivetrain: vehicleInfoDrivetrain,
        })
      );
      dispatch(
        addVehicleActions.Vehicle_Details_Reducer({
          type: "TRANSMISSION",
          vehicle_transmission: vehicleInfoTransmission,
        })
      );
      dispatch(
        addVehicleActions.Vehicle_Details_Reducer({
          type: "EXTERIOR_COLOUR",
          vehicle_exterior_colour: vehicleInfoExteriorColour,
        })
      );
      dispatch(
        addVehicleActions.Vehicle_Details_Reducer({
          type: "INTERIOR_COLOUR",
          vehicle_interior_colour: vehicleInfoInteriorColour,
        })
      );
      dispatch(
        addVehicleActions.Vehicle_Details_Reducer({
          type: "TRIM",
          vehicle_trim: vehicleInfoTrim,
        })
      );
      dispatch(
        addVehicleActions.Vehicle_Details_Reducer({
          type: "DESCRIPTION",
          vehicle_description: vehicleInfoDescription,
        })
      );
      return;
    }
  }, [readyToSendFlag]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  pt-10 border-round bg-athens-gray-500">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
      <div className="max-w-3xl mx-auto border-2 border-opacity-100 rounded-md bg-white">
        {/* Basic info box */}
        <div className="pt-5 pb-5">
          <div>
            <h3 className="text-left ml-5 text-lg leading-6 font-medium text-black">
              Vehicle Details
            </h3>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="drivetrain"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Drivetrain
              </label>
              <div className={isValidInput("drivetrain")}>
                <select
                  id="drivetrain"
                  name="drivetrain"
                  className={inputField_Classname}
                  onChange={(e) => {
                    setVehicleInfo_Drivetrain(e.target.value);
                    dispatch(
                      addVehicleActions.Vehicle_Details_Reducer({
                        type: "DRIVETRAIN",
                        vehicle_drivetrain: e.target.value,
                      })
                    );
                  }}
                >
                  <option value="FWD">FWD </option>
                  <option value="RWD">RWD</option>
                  <option value="AWD">AWD </option>
                  <option value="4x4">4X4</option>
                </select>
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"drivetrain"}
              />
            </div>

            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="transmission"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Transmission
              </label>
              <div className={isValidInput("transmission")}>
                <select
                  id="transmission"
                  name="transmission"
                  className={inputField_Classname}
                  onChange={(e) => {
                    setVehicleInfo_Transmission(e.target.value);
                    dispatch(
                      addVehicleActions.Vehicle_Details_Reducer({
                        type: "TRANSMISSION",
                        vehicle_transmission: e.target.value,
                      })
                    );
                  }}
                >
                  <option value="AUTO">Automatic </option>
                  <option value="MAN">Manual</option>
                </select>
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"transmission"}
              />
            </div>

            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="exteriorColour"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Exterior Colour
              </label>
              <div className={isValidInput("exteriorColor")}>
                <input
                  type="text"
                  name="exteriorColour"
                  id="exteriorColour"
                  className={inputField_Classname}
                  value={vehicleInfoExteriorColour}
                  onChange={(e) => {
                    setVehicleInfo_ExteriorColour(e.target.value);
                    dispatch(
                      addVehicleActions.Vehicle_Details_Reducer({
                        type: "EXTERIOR_COLOUR",
                        vehicle_exterior_colour: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"exteriorColour"}
              />
            </div>

            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="interiorColour"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Interior Colour
              </label>
              <div className={isValidInput("interiorColor")}>
                <input
                  type="text"
                  name="interiorColour"
                  id="interiorColour"
                  className={inputField_Classname}
                  value={vehicleInfoInteriorColour}
                  onChange={(e) => {
                    setVehicleInfo_InteriorColour(e.target.value);
                    dispatch(
                      addVehicleActions.Vehicle_Details_Reducer({
                        type: "INTERIOR_COLOUR",
                        vehicle_interior_colour: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"interiorColour"}
              />
            </div>

            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="trim"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Trim
              </label>
              <div className={isValidInput("trim")}>
                <input
                  type="text"
                  name="trim"
                  id="trim"
                  className={inputField_Classname}
                  value={vehicleInfoTrim}
                  onChange={(e) => {
                    setVehicleInfo_Trim(e.target.value);
                    dispatch(
                      addVehicleActions.Vehicle_Details_Reducer({
                        type: "TRIM",
                        vehicle_trim: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"trim"}
              />
            </div>

            <div className="ml-5 sm:col-span-6 mr-5">
              <label
                htmlFor="description"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-6">
                <textarea
                  className={descClassValid()}
                  value={vehicleInfoDescription}
                  onChange={(e) => {
                    setVehicleInfo_Description(e.target.value);
                    dispatch(
                      addVehicleActions.Vehicle_Details_Reducer({
                        type: "DESCRIPTION",
                        vehicle_description: e.target.value,
                      })
                    );
                  }}
                ></textarea>
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"description"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
