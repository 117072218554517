import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userLoginActions } from "../store/login-slice";
import { loginUserInfo } from "../store/login-actions";
import { ReactComponent as NewPolicaroLogo } from "../component/policaro-logo-image/policaroLogo.svg";

export default function Login() {
  let history = useHistory();
  const dispatch = useDispatch();
  const ERROR_CODE = useSelector((state) => state.login.error_code);
  const ERROR_LOGIN_FLAG = useSelector((state) => state.login.error_login_flag);
  const FORCE_CHANGE_PASSWORD = useSelector(
    (state) => state.login.force_change_password_flag
  );
  // Verified account
  const [email, setUserEmail] = useState("");
  const [password, setUserPassword] = useState("");

  const userEmailHandler = (event) => {
    setUserEmail(event.target.value);
  };

  const userPasswordHandler = (event) => {
    setUserPassword(event.target.value);
  };

  const loginUserHandler = (event) => {
    event.preventDefault();
    dispatch(loginUserInfo(email, password, "")); // accesing the thunk in login-slice
  };

  // this useEffect is to avoid the the page from going back to the login page.
  // if will check if the user already has a login,
  // if token was found, user will redirect to main page.
  // if was not found, use will not be refirected to login
  useEffect(() => {
    const userSession = localStorage.getItem("TOKEN_KEY");
    if (userSession) {
      dispatch(userLoginActions.setAuth({ auth: true })); // true - user is authenticated, false - if not
      history.push("/main-vehicle-table");
    } else {
      history.push("./"); // Unauthorized, go back to login screen
    }
  }, [ERROR_LOGIN_FLAG]);

  useEffect(() => {
    if (FORCE_CHANGE_PASSWORD) {
      dispatch(userLoginActions.setTemporaryPassword({ tempPass: password })); // saving the temp password if there is one
      history.push("./force_reset_password");
    }
  }, [FORCE_CHANGE_PASSWORD]);

  const promptErrorMessage = (code) => {
    let SUCCESS = 0;
    let AWAITING_VALIDATION = 1;

    if (code == SUCCESS) {
      return "";
    } else if (code == AWAITING_VALIDATION) {
      return (
        <div className="mt-5">
          <div className="rounded-md bg-sendRecoveryErrorBg py-5 sm:flex sm:items-start sm:justify-between">
            <div className="text-sendRecoveryErrorFontColor text-left mt-3 sm:mt-0 sm:ml-4 font-normal">
              Your account has not been verified yet. Please visit the link in
              your verification email to verify your account.
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-5">
          <div className="rounded-md bg-sendRecoveryErrorBg py-5 sm:flex sm:items-start sm:justify-between">
            <div className="text-sendRecoveryErrorFontColor text-left mt-3 sm:mt-0 sm:ml-4 font-normal">
              Incorrect email or password. Please try again.
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <div>
            <NewPolicaroLogo className="w-36" />
          </div>
        </div>

        <h1 className="mt-6 text-center text-2xl font-bold text-gray-900">
          Inventory Portal
        </h1>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={loginUserHandler}>
            <div>
              <label
                htmlFor="email"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={userEmailHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={userPasswordHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-end">
              <div className="text-sm">
                <Link
                  className="font-sans text-policaroBlue hover:text-indigo-500"
                  to="/send-recovery-email"
                  onClick={() => {
                    history.push("/send-recovery-email");
                  }}
                >
                  Forgot password
                </Link>
              </div>
            </div>
            {promptErrorMessage(ERROR_CODE)}
            <div>
              {/* SIGN IN BUTTON */}
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-sans text-white bg-policaroBlue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={loginUserHandler}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
