import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, CheckIcon } from "@heroicons/react/outline";
import { CameraIcon } from "@heroicons/react/solid";
import { addVehicleActions } from "../../store/add-vehicle-slice";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import defaultEmpty from "../policaro-logo-image/defaultEmpty.jpeg";
import Modal from "../Modal-Image";

export default function SideVehiclePanel({
  vehicleParamObject,
  showPanelParam,
  rowClickHandler,
}) {
  let history = useHistory();
  const dispatch = useDispatch();

  const [chosenTermId, setChosenTermId] = useState(12);
  const [showMore, setShowMore] = useState(true);
  const [showMoreLabelName, setShowMoreLabelName] = useState("Show more");
  const [openCarousel, setOpenCarousel] = useState(false);
  let showMoreLabelClassname = "";
  let showMoreOverflowHidden = "overflow-hidden h-8 text-sm";
  let showMoreOverflowNotHidden = "overflow-hidden text-sm";

  const showMoreHandler = () => {
    if (showMore == false) {
      setShowMore(true);
      setShowMoreLabelName("Show more");
    } else {
      setShowMoreLabelName("Show less");
      setShowMore(false);
    }
  };

  const imageCarouselHandler = (state) => () => {
    setOpenCarousel(state);
  };

  if (showMore == false) {
    showMoreLabelClassname = showMoreOverflowNotHidden;
  } else {
    showMoreLabelClassname = showMoreOverflowHidden;
  }

  return (
    <Transition.Root show={showPanelParam} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={showPanelParam}
        onClose={rowClickHandler}
      >
        <div className="absolute inset-0 overflow-hidden">
          <div
            className="fixed z-40 inset-0"
            onClick={rowClickHandler(false)}
          />

          <div className="fixed z-50 inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-lg">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6"></div>
                  {/* Main */}
                  <div>
                    <div className="pb-1 sm:pb-6">
                      <div>
                        {/* Image section */}
                        <div className="relative bg-gray-100 h-64 sm:h-64 md:h-64 items-center cursor-pointer">
                          {/* If the object doesn't not have an image. then a default image will be shown*/}

                          {typeof vehicleParamObject.images == "undefined" ||
                          vehicleParamObject.images.length === 0 ? (
                            <img
                              className="h-full w-full object-cover"
                              src={defaultEmpty}
                              alt="Side Panel Main Photo"
                            />
                          ) : (
                            <img
                              className="absolute h-full w-full object-scale-down"
                              src={vehicleParamObject.images[0]["url"]}
                              alt="Side Panel Main Photo"
                              onClick={imageCarouselHandler(true)}
                            />
                          )}

                          <button
                            className="absolute rounded-full top-2 right-2 text-white font-bold uppercase px-10 py-10 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            onClick={rowClickHandler(false)}
                          >
                            <XIcon
                              className=" h-10 w-10 bg-emperor-500 absolute rounded-full top-5 right-5 py-2 px-2"
                              aria-hidden="true"
                            />
                          </button>
                          <div className="absolute right-5 bottom-5 box-border h-8 w-16 border-2 pl-2 items-center justify-center border-transparent bg-emperor-500 grid grid-cols-2 rounded-md">
                            <div>
                              <label
                                htmlFor="file-upload"
                                className="relative right-0 bottom-0 rounded-md bg-white hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              >
                                <CameraIcon
                                  className="h-6 w-6 text-white"
                                  aria-hidden="true"
                                />
                              </label>
                            </div>
                            <div>
                              <p className="text-white">
                                {typeof vehicleParamObject.images == "undefined"
                                  ? 0
                                  : vehicleParamObject.images.length != 0
                                  ? vehicleParamObject.images.length
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="mt-6 px-4 sm:mt-4 sm:flex sm:items-end sm:px-3">
                          <div className="sm:flex-1">
                            <div>
                              <div className="flex items-center">
                                <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">
                                  {vehicleParamObject.year.length == 0
                                    ? "Not Available"
                                    : vehicleParamObject.year}{" "}
                                  {""}
                                  {vehicleParamObject.make.length == 0
                                    ? "Not Available"
                                    : vehicleParamObject.make}{" "}
                                  {vehicleParamObject.model.length == 0
                                    ? "Not Available"
                                    : vehicleParamObject.model}{" "}
                                </h3>
                              </div>

                              <div className="flex items-center">
                                <p className="mr-1 text-base">
                                  {vehicleParamObject.price.length == 0
                                    ? "Not Available"
                                    : vehicleParamObject.price}{" "}
                                </p>
                                <p className="mr-1 text-base">
                                  {vehicleParamObject.currency == null ||
                                  vehicleParamObject.currency.length == 0
                                    ? "N/A"
                                    : vehicleParamObject.currency}{" "}
                                </p>
                                <span className="mx-2 bg-black flex-shrink-0 inline-block h-1 w-1 rounded-full">
                                  <span className="sr-only">Online</span>
                                </span>
                                <p className="mr-1 text-base">
                                  {vehicleParamObject.odometer.length == 0
                                    ? "N/A"
                                    : vehicleParamObject.odometer}{" "}
                                </p>
                                <span className="pl-1 text-sm">
                                  {typeof vehicleParamObject.odometerUnit ==
                                    "undefined" ||
                                  vehicleParamObject.odometerUnit.length == 0
                                    ? "N/A"
                                    : vehicleParamObject.odometerUnit}{" "}
                                </span>{" "}
                                <span className="mx-2 bg-black flex-shrink-0 inline-block h-1 w-1 rounded-full">
                                  <span className="sr-only">Online</span>
                                </span>
                                <span className="text-sm">Created</span>
                                <span className="pl-1 text-sm">
                                  {vehicleParamObject.creation_date == null ||
                                  vehicleParamObject.creation_date.length == 0
                                    ? "N/A"
                                    : new Date(
                                        vehicleParamObject.creation_date * 1000
                                      ).toLocaleDateString("en-US")}{" "}
                                </span>
                              </div>
                            </div>

                            {/* Buttons */}
                            <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-1">
                              <div className="flex w-12 sm:w-14 md:sm:w-32">
                                <button
                                  type="button"
                                  className="text-xs font-sans items-center justify-center text-white bg-policaroBlue mr-5 inline-flex px-2.5 py-1.5 border border-opacity-100 font-medium rounded shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  onClick={() => {
                                    dispatch(
                                      addVehicleActions.EditOrAddFlag({
                                        current_flag: false,
                                      })
                                    );
                                    history.push({
                                      pathname: "./add-vehicle",
                                      state: {
                                        vehicle_information: vehicleParamObject,
                                        isFromAddVehicleBtn: false,
                                      },
                                    });
                                  }}
                                >
                                  Edit
                                </button>
                              </div>
                              <div className="text-xs font-sans items-center justify-center text-black bg-white mr-5 inline-flex px-2.5 border border-opacity-100 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <label>Status: </label>
                                <label className="font-medium bg-transparent focus:outline-none px-1">
                                  {vehicleParamObject.availability
                                    .toLowerCase()
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                      letter.toUpperCase()
                                    )}
                                </label>
                              </div>
                              <div className="flex-1 w-full inline-flex items-center justify-center border border-opacity-100 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                {vehicleParamObject.included == true ? (
                                  <div className="flex items-center">
                                    <div>
                                      <CheckIcon className="h-5 w-5 text-policaroBlue" />
                                    </div>
                                    <div>
                                      <label className="px-1 text-policaroBlue text-md">
                                        {" "}
                                        Included On Leasing Site{" "}
                                      </label>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center justify-start">
                                    <div>
                                      <XIcon className="h-5 w-5 text-secondaryText mr-1" />
                                    </div>

                                    <div>
                                      <label className="text-secondaryText text-md">
                                        {" "}
                                        Not Included On Leasing Site{" "}
                                      </label>
                                    </div>
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relative  bg-white border-t-2 py-2 w-full" />

                    <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                      <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                        <p>Vehicle Details </p>
                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Odometer
                          </dt>
                          <dd className="md:mt-0 md:col-span-7">
                            {vehicleParamObject.odometer.length === 0 ? (
                              <p className="italic text-gray-500">
                                Not Available
                              </p>
                            ) : (
                              <p className="text-base text-vehicleTableFontColor">
                                {vehicleParamObject.odometer}{" "}
                                <label className="text-gray-500">
                                  {vehicleParamObject.odometerUnit.toLowerCase()}{" "}
                                </label>
                              </p>
                            )}
                          </dd>
                        </div>
                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Stock #:
                          </dt>
                          <dd className="mt-2 md:mt-0 md:col-span-7">
                            <div className="text-base text-vehicleTableFontColor">
                              {vehicleParamObject.stockNumber.length === 0 ? (
                                <p className="italic">Not Available</p>
                              ) : (
                                vehicleParamObject.stockNumber
                              )}
                            </div>
                          </dd>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            VIN:
                          </dt>
                          <dd className="mt-2 md:mt-0 md:col-span-7 overflow-auto">
                            <div className="text-base text-vehicleTableFontColor">
                              {vehicleParamObject.vin.length === 0 ? (
                                <p className="italic">Not Available</p>
                              ) : (
                                vehicleParamObject.vin
                              )}
                            </div>
                          </dd>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-8 ">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5 mr-1">
                            Dealer
                          </dt>
                          <dd className="mt-2 md:mt-0 md:col-span-7 overflow-auto">
                            <div className="text-base text-vehicleTableFontColor">
                              {vehicleParamObject.dealer_name == null ||
                              vehicleParamObject.dealer_name.length === 0 ? (
                                <p className="italic">Not Available</p>
                              ) : (
                                vehicleParamObject.dealer_name
                              )}
                            </div>
                          </dd>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Trim:
                          </dt>
                          <dd className="mt-2 md:mt-0 md:col-span-7">
                            <div className="text-base text-vehicleTableFontColor italic">
                              {vehicleParamObject.trim.length === 0 ? (
                                <p className="italic">Not Available</p>
                              ) : (
                                vehicleParamObject.trim
                              )}
                            </div>
                          </dd>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Ext. Colour:
                          </dt>
                          <dd className="mt-2 md:mt-0 md:col-span-7">
                            <div className="text-base text-vehicleTableFontColor">
                              {vehicleParamObject.exteriorColor.length === 0 ? (
                                <p className="italic">Not Available</p>
                              ) : (
                                vehicleParamObject.exteriorColor
                              )}
                            </div>
                          </dd>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Drivetrain
                          </dt>
                          <dd className="md:mt-0 md:col-span-7">
                            <div className="text-base text-vehicleTableFontColor">
                              {vehicleParamObject.drivetrain.length === 0 ? (
                                <p className="italic">Not Available</p>
                              ) : (
                                vehicleParamObject.drivetrain
                              )}
                            </div>
                          </dd>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Transmission
                          </dt>
                          <dd className="md:mt-0 md:col-span-7">
                            <div className="text-base text-vehicleTableFontColor">
                              {vehicleParamObject.transmission.length === 0 ? (
                                <p className="italic">Not Available</p>
                              ) : (
                                vehicleParamObject.transmission
                              )}
                            </div>
                          </dd>
                        </div>

                        <div>
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Description:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                            {vehicleParamObject.description.length === 0 ? (
                              <p className="italic">Not Available</p>
                            ) : (
                              <p className={showMoreLabelClassname}>
                                {vehicleParamObject.description}
                              </p>
                            )}
                          </dd>
                          <button
                            className="text-policaroBlue pt-3"
                            onClick={showMoreHandler}
                          >
                            <label>{showMoreLabelName}</label>
                          </button>
                        </div>
                      </dl>

                      <div className="relative  bg-white border-t-2 py-3 w-full mt-5" />

                      <dl className="space-y-8 px-4 sm:px-6 sm:space-y-4">
                        <p>Listing Details </p>
                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Price
                          </dt>
                          <dd className="md:mt-0 md:col-span-7">
                            {typeof vehicleParamObject.price == "undefined" ||
                            vehicleParamObject.price.length === 0 ? (
                              <p className="italic text-vehicleTableFontColor">
                                Not Available
                              </p>
                            ) : (
                              <p className="text-base text-vehicleTableFontColor">
                                {vehicleParamObject.price}{" "}
                                <label className="text-gray-500">
                                  {vehicleParamObject.currency}{" "}
                                </label>
                              </p>
                            )}
                          </dd>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Interest Rate
                          </dt>
                          <dd className="md:mt-0 md:col-span-7">
                            <div className="text-base text-vehicleTableFontColor">
                              {vehicleParamObject.interest_rate == null ||
                              vehicleParamObject.interest_rate.length === 0 ? (
                                <p className="italic text-vehicleTableFontColor ">
                                  Not Available
                                </p>
                              ) : (
                                <p className="italic text-vehicleTableFontColor">
                                  {vehicleParamObject.interest_rate} %
                                </p>
                              )}
                            </div>
                          </dd>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-8">
                          <dt className="text-base font-medium text-gray-900 md:col-span-5">
                            Product Category
                          </dt>
                          <dd className="md:mt-0 md:col-span-7">
                            <div className="text-base text-vehicleTableFontColor">
                              {vehicleParamObject.product_category == null ||
                              vehicleParamObject.product_category.length ===
                                0 ? (
                                <p className="italic">Not Available</p>
                              ) : (
                                <p>{vehicleParamObject.product_category}</p>
                              )}
                            </div>
                          </dd>
                        </div>
                        {typeof vehicleParamObject.residuals != "undefined" && (
                          <div>
                            {vehicleParamObject.residuals.length != 0 && (
                              <div className="grid grid-cols-6 grid-rows-3 items-center justify-center box-border h-32 w-full p-4 border-2 bg-residualTableRowColorGrey border-textInputBackground rounded-md">
                                <div className="col-span-6 row-span-3">
                                  <p>Residuals</p>
                                </div>
                                <div className="col-span-3 row-span-2 h-12 text-sm font-sans items-center justify-center text-black bg-white mr-5 inline-flex px-2.5 border border-opacity-100 font-medium rounded shadow-sm">
                                  <p>Term: </p>
                                  <select
                                    onChange={(e) =>
                                      setChosenTermId(+e.target.value)
                                    }
                                  >
                                    <option value="12">12 months</option>
                                    <option value="18">18 months</option>
                                    <option value="24">24 months</option>
                                    <option value="30">30 months</option>
                                    <option value="36">36 months</option>
                                    <option value="42">42 months</option>
                                    <option value="48">48 months</option>
                                  </select>
                                </div>
                                <div className="flex col-span-3 ">
                                  <div className="col-span-2 text-xs overflow-auto items-center justify-center overflow-y-auto pb-5">
                                    {Object.keys(
                                      vehicleParamObject.residuals
                                    ).map((row, rowIndex) => (
                                      <div key={rowIndex}>
                                        {rowIndex % 6 == 0 && rowIndex != 0 && (
                                          <p className="text-sm">
                                            {" "}
                                            {
                                              vehicleParamObject.residuals[row][
                                                "distance"
                                              ]
                                            }{" "}
                                            km / year
                                          </p>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                  <div className="col-span-1 text-sm pl-5 overflow-y-auto pb-5">
                                    {Object.keys(
                                      vehicleParamObject.residuals
                                    ).map((row, rowIndex) => (
                                      <div key={rowIndex}>
                                        {vehicleParamObject.residuals[row][
                                          "term"
                                        ] == chosenTermId && (
                                          <div>
                                            <p className="font-bold">
                                              {
                                                vehicleParamObject.residuals[
                                                  row
                                                ]["value"]
                                              }{" "}
                                              {"%"}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                            <Modal
                              vehicleImageList={vehicleParamObject.images}
                              isOpen={openCarousel}
                              openHandler={imageCarouselHandler}
                              currentSelectedImage={0}
                            />
                          </div>
                        )}
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
