import { configureStore } from "@reduxjs/toolkit";

import loginSlice from "./login-slice";
import sendEmailRecoverySlice from "./send-recovery-email-slice";
import resetPasswordSlice from "./reset-password-slice";
import vehicleListSlice from "./vehicle-list-slice";
import addVehicleSlice from "./add-vehicle-slice";
import mainTableSlice from "./main-table-slice";
import searchBarSlice from "./search-bar-slice";
import toastMessageSlice from "./toast-message-slice";

const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    send_recovery: sendEmailRecoverySlice.reducer,
    reset_password: resetPasswordSlice.reducer,
    vehicle_list: vehicleListSlice,
    add_vehicle: addVehicleSlice.reducer,
    main_table: mainTableSlice.reducer,
    search_bar: searchBarSlice.reducer,
    toast_messages: toastMessageSlice.reducer,
  },
});

export default store;
