import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVehicleActions } from "../../store/add-vehicle-slice";
import InputErrorSection from "./Input-Error-Section";

export default function BasicInformation(props) {
  const {
    year,
    make,
    model,
    odometer,
    odometerUnit,
    vin,
    stockNumber,
    dealer_name,
  } = props.parentVehicle;
  const inputErrors = props.inputErrors;

  const initialUpdate = useRef(true);
  const dispatch = useDispatch();
  const readyToSendFlag = useSelector((state) => state.add_vehicle.SEND_FLAG);

  const [vehicleYear, setVehicleYear] = useState(year);
  const [vehicleMake, setVehicleMake] = useState(make);
  const [vehicleModel, setVehicleModel] = useState(model);
  const [vehicleOdometer, setVehicleOdometer] = useState(odometer);
  const [vehicleMetric, setVehicleMetric] = useState(
    odometerUnit ? odometerUnit : "KM"
  );

  const [vehicleVin, setVehicleVin] = useState(vin);
  const [vehicleStock, setVehicleStock] = useState(stockNumber);
  const [vehicleDealer, setVehicleDealer] = useState(
    dealer_name ? dealer_name : "Motion Endeavours"
  );

  let inputField_Classname =
    "pl-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full sm:text-sm border-gray-300 rounded-md";

  var isValidInputClass = (field) => {
    if (field in inputErrors) {
      return "mt-1 border-2 border-opacity-50 border-red-500 rounded-md h-10";
    } else {
      return "mt-1 border-2 border-opacity-50 border-textInputBorder rounded-md h-10";
    }
  };

  // If the readyToSendFlag state is changed, then the useEffect will trigger
  useEffect(() => {
    if (initialUpdate.current) {
      initialUpdate.current = false;
      // Initializing the redux states with the vehicle object param info
      dispatch(
        addVehicleActions.Basic_Information_Reducer({
          type: "YEAR",
          vehicle_year: year,
        })
      );
      dispatch(
        addVehicleActions.Basic_Information_Reducer({
          type: "MAKE",
          vehicle_make: vehicleMake,
        })
      );
      dispatch(
        addVehicleActions.Basic_Information_Reducer({
          type: "MODEL",
          vehicle_model: vehicleModel,
        })
      );
      dispatch(
        addVehicleActions.Basic_Information_Reducer({
          type: "ODOMETER",
          vehicle_odometer: vehicleOdometer,
        })
      );
      dispatch(
        addVehicleActions.Basic_Information_Reducer({
          type: "METRIC",
          vehicle_metric: vehicleMetric,
        })
      );
      dispatch(
        addVehicleActions.Basic_Information_Reducer({
          type: "VIN",
          vehicle_vin: vehicleVin,
        })
      );
      dispatch(
        addVehicleActions.Basic_Information_Reducer({
          type: "STOCK_NUMBER",
          vehicle_stock_number: vehicleStock,
        })
      );
      dispatch(
        addVehicleActions.Basic_Information_Reducer({
          type: "DEALER_NAME",
          vehicle_dealer_name: vehicleDealer,
        })
      );
      return;
    }
  }, [readyToSendFlag]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  pt-5 border-round bg-athens-gray-500">
      <div className="max-w-3xl mx-auto border-2 border-opacity-100  rounded-md bg-white">
        {/* Basic info box */}
        <div className="pt-5 pb-5">
          <div>
            <h3 className="text-left ml-5 text-lg leading-6 font-medium text-gray-900">
              Basic Information
            </h3>
          </div>

          <div className="mt-3 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
            {/* Year */}
            <div className="ml-5 sm:col-span-1">
              <label
                htmlFor="year"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Year <span className="text-removeButtonBackground">*</span>
              </label>
              <div className={isValidInputClass("year")}>
                <input
                  type="text"
                  name="year"
                  id="year"
                  className={inputField_Classname}
                  value={vehicleYear}
                  onChange={(e) => {
                    setVehicleYear(e.target.value);
                    dispatch(
                      addVehicleActions.Basic_Information_Reducer({
                        type: "YEAR",
                        vehicle_year: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"year"}
              />
            </div>

            {/*  Make */}
            <div className="ml-5 sm:col-span-2">
              <label
                htmlFor="make"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Make <span className="text-removeButtonBackground">*</span>
              </label>
              <div className={isValidInputClass("make")}>
                <input
                  type="text"
                  name="make"
                  id="make"
                  className={inputField_Classname}
                  value={vehicleMake}
                  onChange={(e) => {
                    setVehicleMake(e.target.value);
                    dispatch(
                      addVehicleActions.Basic_Information_Reducer({
                        type: "MAKE",
                        vehicle_make: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"make"}
              />
            </div>

            {/*  Model */}
            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="model"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Model <span className="text-removeButtonBackground">*</span>
              </label>
              <div className={isValidInputClass("model")}>
                <input
                  type="text"
                  name="model"
                  id="model"
                  className={inputField_Classname}
                  value={vehicleModel}
                  onChange={(e) => {
                    setVehicleModel(e.target.value);
                    dispatch(
                      addVehicleActions.Basic_Information_Reducer({
                        type: "MODEL",
                        vehicle_model: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"model"}
              />
            </div>

            {/*  Odometer */}
            <div className="ml-5 sm:col-span-2">
              <label
                htmlFor="odometer"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Odometer <span className="text-removeButtonBackground">*</span>
              </label>
              <div className={isValidInputClass("odometer")}>
                <input
                  type="text"
                  name="odometer"
                  id="odometer"
                  className={inputField_Classname}
                  value={vehicleOdometer}
                  onChange={(e) => {
                    setVehicleOdometer(e.target.value);
                    dispatch(
                      addVehicleActions.Basic_Information_Reducer({
                        type: "ODOMETER",
                        vehicle_odometer: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"odometer"}
              />
            </div>

            {/*  Metric */}
            <div className="sm:col-span-1">
              <div className={`${isValidInputClass("odometerUnit")} mt-6`}>
                <select
                  type="number"
                  name="odometerUnit"
                  id="odometerUnit"
                  className={inputField_Classname}
                  value={vehicleMetric}
                  onChange={(e) => {
                    setVehicleMetric(e.target.value);
                    dispatch(
                      addVehicleActions.Basic_Information_Reducer({
                        type: "METRIC",
                        vehicle_metric: e.target.value,
                      })
                    );
                  }}
                >
                  <option value="KM">km</option>
                  <option value="MI">mi</option>
                </select>
              </div>
            </div>

            {/*  VIN */}
            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="vin"
                className="text-left block text-sm font-medium text-gray-700"
              >
                VIN <span className="text-removeButtonBackground">*</span>
              </label>
              <div className={isValidInputClass("vin")}>
                <input
                  type="text"
                  name="vin"
                  id="vin"
                  className={inputField_Classname}
                  value={vehicleVin}
                  onChange={(e) => {
                    setVehicleVin(e.target.value);
                    dispatch(
                      addVehicleActions.Basic_Information_Reducer({
                        type: "VIN",
                        vehicle_vin: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection inputErrors={inputErrors} inputField={"vin"} />
            </div>

            {/*  Stock */}
            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="stockNumber"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Stock # <span className="text-removeButtonBackground">*</span>
              </label>
              <div className={isValidInputClass("stockNumber")}>
                <input
                  type="text"
                  name="stockNumber"
                  id="stockNumber"
                  className={inputField_Classname}
                  value={vehicleStock}
                  onChange={(e) => {
                    setVehicleStock(e.target.value);
                    dispatch(
                      addVehicleActions.Basic_Information_Reducer({
                        type: "STOCK_NUMBER",
                        vehicle_stock_number: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"stockNumber"}
              />
            </div>

            {/*  Dealer */}
            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="dealerName"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Dealer <span className="text-removeButtonBackground">*</span>
              </label>
              <div className={isValidInputClass("dealer_name")}>
                <select
                  id="dealerName"
                  name="dealerName"
                  className={inputField_Classname}
                  value={vehicleDealer}
                  onChange={(e) => {
                    setVehicleDealer(e.target.value);
                    dispatch(
                      addVehicleActions.Basic_Information_Reducer({
                        type: "DEALER_NAME",
                        vehicle_dealer_name: e.target.value,
                      })
                    );
                  }}
                >
                  <option value="Motion Endeavours">Motion Endeavours </option>
                  <option value="Porsche Oakville Centre">
                    {" "}
                    Porsche Oakville Centre
                  </option>
                  <option value="Policaro BMW">Policaro BMW </option>
                  <option value="Policaro Acura">Policaro Acura</option>
                  <option value="Northwest Lexus">Northwest Lexus </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
