import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isAuthenticated: false, // IF true, the page will jump to the main screen
    error_code: "", // save error code for error messages
    error_login_flag: false, // true - user logs in, false - user logs out
    force_change_password_flag: false, // if true, the user must change password
    temporary_password: "",
  },
  reducers: {
    setAuth(state, action) {
      state.isAuthenticated = action.payload.auth;
    },

    updateErrorLoginFlag(state, action) {
      // true - user logs in, false - user logs out
      state.error_login_flag = action.payload.loginUser;
    },

    // Save error message
    saveErrorCode(state, action) {
      state.error_code = action.payload.response_code;
    },

    // if True, the user must change the password
    setChangePasswordFlag(state, action) {
      state.force_change_password_flag = action.payload.flagState;
    },

    // Set temp password for newly created accoutns
    setTemporaryPassword(state, action) {
      state.temporary_password = action.payload.tempPass;
    },
  },
});

export const userLoginActions = loginSlice.actions;
export default loginSlice;
