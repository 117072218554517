import { createSlice } from "@reduxjs/toolkit";

const toastMessageSlice = createSlice({
  name: "toast-message",
  initialState: {
    toastStatus: "", // Added, Starring, Removing, Updating
    toastVehicleName: "",
    isToastVisible: false,
    // Use for the displaying of blue background to new added vehicles
    lastVehicleAddedId: null, // use to save the id of the last added vehicle
    lastVehicleBlueBackgroundFlag: false, // true, the background of the new added vehicle on main table is blue, false, bg will be white
  },
  reducers: {
    setToastInformation: (state, action) => {
      return {
        toastStatus: action.payload.status,
        toastVehicleName: action.payload.name,
        isToastVisible: action.payload.showToast,
      };
    },

    setShowToasts: (state, action) => {
      state.isToastVisible = action.payload.show;
    },

    setShowBlueFlag: (state, action) => {
      state.lastVehicleBlueBackgroundFlag = action.payload.flagColor;
    },

    setNewVehicleTableBackgroundToBlue(state, action) {
      state.lastVehicleAddedId = action.payload.vehicleId;
      state.lastVehicleBlueBackgroundFlag = action.payload.flag;
    },
  },
});

export const {
  setToastInformation,
  setShowToasts,
  setNewVehicleTableBackgroundToBlue,
  setShowBlueFlag,
} = toastMessageSlice.actions;
export default toastMessageSlice;
