import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function ResidualTable(props) {
  const IS_FROM_ADD_VEHICHLE_BTN = useSelector(
    (state) => state.add_vehicle.ADD_VEHICLE_FLAG
  );

  let dataTable = [];

  if (IS_FROM_ADD_VEHICHLE_BTN == true) {
    dataTable = props.residualTable;
  } else {
    dataTable = JSON.parse(JSON.stringify(props.residualTable));
  }

  const [isInvalid, setIsInvalid] = useState(false);
  const [matrix, setMatrix] = useState(dataTable);

  let validState =
    "relative flex items-stretch flex-grow focus-within:z-10 border border-black-500 rounded-md";

  let inValidState =
    "relative flex items-stretch flex-grow focus-within:z-10 border border-red-500 rounded-md";

  let headerCell =
    "px-6 py-3 text-left text-lg font-medium text-black uppercase tracking-wider";

  let rowColorWhite = "bg-residualTableRowColorWhite";
  let rowColorGrey = "bg-residualTableRowColorGrey";

  const handleChange = (row, column) => (event) => {
    let copy = JSON.parse(JSON.stringify(matrix)); // create a copy of the current rate table
    copy[row][column] = +event.target.value; // change a cell on the copy of the create table.
    setMatrix(copy); // update the interest rate table with the new updated state of the table
    props.residualTableHander(copy);

    if (!Number(event.target.value)) {
      setIsInvalid(true);
    } else {
      if (+event.target.value == 0 || +event.target.value > 100) {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
      }
    }
  };

  // access the residual table handler on the parent and change the redux residual table state to usable format
  useEffect(() => {
    props.residualTableHander(matrix);
  });

  return (
    <>
      <div className="flex flex-col mx-5 mt-5">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="flex-nowrap min-w-full max-w-full md:min-w-0 divide-y divide-gray-200 ">
                <tbody>
                  <tr>
                    <th
                      scope="col"
                      className="w-12 px-6 py-3 text-left text-sm font-medium text-black tracking-wider"
                    >
                      Term(months)
                    </th>
                    <th scope="col" className={headerCell}>
                      12
                    </th>
                    <th scope="col" className={headerCell}>
                      18
                    </th>
                    <th scope="col" className={headerCell}>
                      24
                    </th>

                    <th scope="col" className={headerCell}>
                      30
                    </th>
                    <th scope="col" className={headerCell}>
                      36
                    </th>

                    <th scope="col" className={headerCell}>
                      42
                    </th>

                    <th scope="col" className={headerCell}>
                      48
                    </th>
                  </tr>
                  {Object.keys(matrix).map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className={
                        rowIndex % 2 === 0 ? rowColorWhite : rowColorGrey
                      }
                    >
                      {/* Adding comma to term lenghts */}
                      <td className="text-left px-6 py-4 whitespace-nowrap text-sm font-medium text-black rounded-md ">
                        {`${parseFloat(row).toLocaleString()}`}
                        <label className="text-black">km/year</label>
                      </td>

                      {Object.keys(matrix[row]).map((column, columnIndex) => (
                        <td
                          key={columnIndex}
                          className="text-left px-1 py-4 whitespace-nowrap text-sm text-gray-500 rounded-md"
                        >
                          <div
                            className={
                              matrix[row][column] > 100 ||
                              matrix[row][column] === 0 ||
                              matrix[row][column] < 0 ||
                              !Number(matrix[row][column]) == true
                                ? inValidState
                                : validState
                            }
                          >
                            <input
                              readOnly={false}
                              type="text"
                              name="residual-table"
                              id="residual-table"
                              className="rounded-md placeholder-black-300 sm:pl-0 md:pl-2 lg:pl-3 border-1 border-opacity-10 h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-black"
                              defaultValue={matrix[row][column]}
                              onChange={handleChange(row, column)}
                            ></input>
                            <span className="bg-textInputBackground inline-flex items-center space-x-2 sm:px-2 md:px-2 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                              <span className="text-grey2">%</span>
                            </span>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {isInvalid && (
              <p className="pl-5 text-left mt-2 text-sm text-red-600">
                Enter a number between 0 to 100
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
