import "./Main-Table-Footer.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { mainTableSliceActions } from "../store/main-table-slice";
import Pagination from "@mui/material/Pagination";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MainTableFooter() {
  const dispatch = useDispatch();
  const perPage = useSelector((state) => state.main_table.showPerPageValue);
  const currentPage = useSelector(
    (state) => state.main_table.currentPageNumber
  );
  const totalPages = useSelector((state) => state.main_table.totalPages);

  const changePage = (event, value) => {
    dispatch(mainTableSliceActions.setCurrentPage({ pageNumber: value }));
  };

  const incrementPage = () => {
    dispatch(mainTableSliceActions.incrementPage());
  };

  const decrementPage = () => {
    dispatch(mainTableSliceActions.decrementPage());
  };

  const ShowPerPageHandler = (perPageValue) => () => {
    dispatch(
      mainTableSliceActions.setShowPerPageValue({ show_per_page: perPageValue })
    );
  };

  return (
    <div className="flex justify-between px-5 bg-white">
      <div className="py-3 relative flex items-center justify-between  sm:px-6">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              Show per page: {perPage}
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-bottom-left pop-upward-menu rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-lg text-center"
                      )}
                      onClick={ShowPerPageHandler(10)} // setting the showperpage value to 10
                    >
                      10
                    </a>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-lg text-center"
                      )}
                      onClick={ShowPerPageHandler(15)} // setting the showperpage value to 10
                    >
                      15
                    </a>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-lg text-center"
                      )}
                      onClick={ShowPerPageHandler(20)} // setting the showperpage value to 10
                    >
                      20
                    </a>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-lg text-center"
                      )}
                      //   onClick={ShowPerPageHandler(25)} // setting the showperpage value to 10
                      onClick={ShowPerPageHandler(25)} // setting the showperpage value to 10
                    >
                      25
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {totalPages > 1 ? (
        <Pagination
          page={currentPage}
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={changePage}
          className="px-4 py-3"
          hidePrevButton
          hideNextButton
        />
      ) : (
        ""
      )}

      <div className="bg-white">
        <nav
          className="px-4 py-3 flex items-center justify-between sm:px-6 "
          aria-label="Pagination"
        >
          <div className="flex-1 flex justify-between sm:justify-end">
            {currentPage > 1 ? (
              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={decrementPage}
              >
                Previous
              </a>
            ) : (
              ""
            )}
            {currentPage < totalPages ? (
              <a
                href="#"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={incrementPage}
              >
                Next
              </a>
            ) : (
              ""
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}
