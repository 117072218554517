import { createSlice } from "@reduxjs/toolkit";

const resetPasswordSlice = createSlice({
  name: "reset-password",
  initialState: {
    PASSWORD_CHANGED_SUCCESSFULLY_FLAG: false,
    errorResponseCode: "",
  },

  reducers: {
    passwordChangeSuccessfully(state, action) {
      // if true, reset password is successful and redirect screen.
      state.PASSWORD_CHANGED_SUCCESSFULLY_FLAG =
        action.payload.passwordChangeFlag;
    },

    saveQueryErrorResponseCode(state, action) {
      state.errorResponseCode = action.payload.code;
    },
  },
});

export const resetAccountPassword = resetPasswordSlice.actions;
export default resetPasswordSlice;
