import { Switch } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { addVehicleActions } from "../../store/add-vehicle-slice";
import * as DefaultTables from "./Table/DefaultTableValues";
import ResidualTable from "./Table/Residual-Table";
import InputErrorSection from "./Input-Error-Section";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ListingDetails(props) {
  const {
    price,
    currency,
    interest_rate,
    product_category,
    residuals,
    availability,
    included,
  } = props.parentVehicle;
  const inputErrors = props.inputErrors;

  const initialUpdate = useRef(true);
  // eslint-disable-next-line no-unused-vars
  const [isAllValid, setIsAllValid] = useState(true);

  const [vehicleListingPrice, setVehicleListingPrice] = useState(
    price.indexOf("$") == 0 ? price.substr(1) : price
  );
  const [vehicleListingCurrency, setVehicleListingCurrency] = useState(
    currency ? currency : "CAD"
  );

  const [vehicleListingRate, setVehicleListingRate] = useState(
    interest_rate ? interest_rate : ""
  );

  const [vehicleListingProductCategory, setVehicleListingProductCategory] =
    useState(product_category ? product_category : "");

  const [vehicleListingResidualTable, setVehicleResidualTable] =
    useState(residuals);

  const [vehicleListingAvailability, setVehicleListingStatus] = useState(
    availability ? availability : "NOT AVAILABLE"
  );

  const [enabled, setEnabled] = useState(included ? true : false);

  const dispatch = useDispatch();

  const readyToSendFlag = useSelector((state) => state.add_vehicle.SEND_FLAG);
  // This state is to check if the form is rendered from add or edit flow
  const IS_FROM_ADD_VEHICHLE_BTN = useSelector(
    (state) => state.add_vehicle.ADD_VEHICLE_FLAG
  );

  var isValidInputClass = (field) => {
    if (field in inputErrors) {
      return "flex items-stretch flex-grow focus-within:z-10 border-2 border-opacity-50 border-red-500 rounded-md h-10 ";
    } else {
      return "flex items-stretch flex-grow focus-within:z-10 border-2 border-opacity-50 border-textInputBorder rounded-md h-10";
    }
  };

  // handles changes on residual table
  const Residual_Table_Handler = (newTable) => {
    setVehicleResidualTable(newTable);
    dispatch(
      addVehicleActions.Listing_Details_Reducer({
        type: "RESIDUAL_TABLE",
        vehicle_residual_table: newTable,
      })
    );
  };

  const ClearProductCategoryHandler = () => {
    setVehicleListingProductCategory("");
    dispatch(
      addVehicleActions.Listing_Details_Reducer({
        type: "PRODUCT_CATEGORY",
        vehicle_product_category: "",
      })
    );
  };

  const ProductCategoryHandler = (event) => {
    setVehicleListingProductCategory(event.target.value);
    dispatch(
      addVehicleActions.Listing_Details_Reducer({
        type: "PRODUCT_CATEGORY",
        vehicle_product_category: event.target.value,
      })
    );
  };

  const formatDBResiduals = (residuals) => {
    let formattedResiduals = {};
    let residualList = residuals;
    if (residualList) {
      // If the recieved residuals is not an array, convert it into one
      if (!residualList.length) {
        residualList = Object.entries(residualList).map((r) => ({
          [r[0]]: r[1],
        }));
      }

      for (let res of residualList) {
        if (!formattedResiduals[res.distance]) {
          formattedResiduals[res.distance] = {
            [res.term]: res.value,
          };
        } else {
          formattedResiduals[res.distance][res.term] = res.value;
        }
      }
    }
    return formattedResiduals;
  };

  // sideeffect that will change the current table on the redux according to product category
  useEffect(() => {
    if (residuals != "" && product_category == vehicleListingProductCategory) {
      setVehicleResidualTable(vehicleListingResidualTable);
    } else {
      if (vehicleListingProductCategory == "Pre-Owned Supercar") {
        setVehicleResidualTable(DefaultTables.tableDataSuperCar);
        dispatch(
          addVehicleActions.Listing_Details_Reducer({
            type: "RESIDUAL_TABLE",
            vehicle_residual_table: DefaultTables.tableDataSuperCar,
          })
        );
      } else if (vehicleListingProductCategory == "Pre-Owned Mainline") {
        setVehicleResidualTable(DefaultTables.tableDataMainLine);
        dispatch(
          addVehicleActions.Listing_Details_Reducer({
            type: "RESIDUAL_TABLE",
            vehicle_residual_table: DefaultTables.tableDataMainLine,
          })
        );
      } else if (vehicleListingProductCategory == "Pre-Owned Highline") {
        setVehicleResidualTable(DefaultTables.tableDataHighline);
        dispatch(
          addVehicleActions.Listing_Details_Reducer({
            type: "RESIDUAL_TABLE",
            vehicle_residual_table: DefaultTables.tableDataHighline,
          })
        );
      } else if (vehicleListingProductCategory == "") {
        setVehicleResidualTable([]);
        dispatch(
          addVehicleActions.Listing_Details_Reducer({
            type: "RESIDUAL_TABLE",
            vehicle_residual_table: [],
          })
        );
      }
    }
  }, [vehicleListingProductCategory]);

  // Initialize the states on redux
  useEffect(() => {
    if (initialUpdate.current) {
      dispatch(
        addVehicleActions.Listing_Details_Reducer({
          type: "PRICE",
          vehicle_price: vehicleListingPrice,
        })
      );
      dispatch(
        addVehicleActions.Listing_Details_Reducer({
          type: "CURRENCY",
          vehicle_currency: vehicleListingCurrency,
        })
      );
      dispatch(
        addVehicleActions.Listing_Details_Reducer({
          type: "INTEREST_RATE",
          vehicle_interest_rate: vehicleListingRate,
        })
      );
      dispatch(
        addVehicleActions.Listing_Details_Reducer({
          type: "PRODUCT_CATEGORY",
          vehicle_product_category: vehicleListingProductCategory,
        })
      );
      dispatch(
        addVehicleActions.Listing_Details_Reducer({
          type: "RESIDUAL_TABLE",
          vehicle_residual_table: vehicleListingResidualTable,
        })
      );
      dispatch(
        addVehicleActions.Listing_Details_Reducer({
          type: "STATUS",
          vehicle_availability: vehicleListingAvailability,
        })
      );
      dispatch(
        addVehicleActions.Listing_Details_Reducer({
          type: "ONLEASING",
          onLeasing: enabled,
        })
      );
      initialUpdate.current = false;
      return;
    }

    if (vehicleListingPrice.length == 0 || vehicleListingPrice < 0) {
      setIsAllValid(false);
      props.validateInput.current = false;
    } else {
      setIsAllValid(true);
      props.validateInput.current = true;
    }
  }, [readyToSendFlag]);

  useEffect(() => {
    dispatch(
      addVehicleActions.Listing_Details_Reducer({
        type: "ONLEASING",
        onLeasing: enabled,
      })
    );
  }, [enabled]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  pt-10 border-round pb-7 bg-athens-gray-500">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
      <div className="max-w-3xl mx-auto border-2 border-opacity-100 rounded-md bg-white">
        <div className="pt-5 pb-5">
          <div>
            <h3 className="text-left ml-5 text-lg leading-6 font-medium text-black">
              Listing Details
            </h3>
          </div>

          <div className="mt-6 grid gap-y-4 gap-x-1 sm:grid-cols-6">
            <div className="ml-5 sm:col-span-2">
              <label
                htmlFor="price"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Price <span className="text-sendRecoveryErrorFontColor">*</span>
              </label>

              <div className={isValidInputClass("price")}>
                <span className="inline-flex items-center space-x-2 px-3 py-2 border border-gray-300 text-sm font-medium rounded-l-md text-gray-700 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                  <span className="text-grey2">$</span>
                </span>
                <input
                  id="price"
                  name="price"
                  className="pl-2 w-full"
                  value={vehicleListingPrice}
                  onChange={(e) => {
                    setVehicleListingPrice(e.target.value);
                    dispatch(
                      addVehicleActions.Listing_Details_Reducer({
                        type: "PRICE",
                        vehicle_price: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"price"}
              />
            </div>

            <div className="ml-2 sm:col-span-1 mr-3">
              <div className="mt-5 border-2 border-opacity-50 border-textInputBorder rounded-md h-10">
                <select
                  id="currency"
                  name="currency"
                  className="pl-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full sm:text-sm border-gray-300 rounded-md"
                  value={vehicleListingCurrency}
                  onChange={(e) => {
                    setVehicleListingCurrency(e.target.value);
                    dispatch(
                      addVehicleActions.Listing_Details_Reducer({
                        type: "CURRENCY",
                        vehicle_currency: e.target.value,
                      })
                    );
                  }}
                >
                  <option value="CAD">CAD </option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="interest_rate"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Interest Rate
              </label>
              <div className={isValidInputClass("interest_rate")}>
                <input
                  id="interest_rate"
                  name="interest_rate"
                  className="pl-2 w-full"
                  value={vehicleListingRate}
                  onChange={(e) => {
                    setVehicleListingRate(e.target.value);
                    dispatch(
                      addVehicleActions.Listing_Details_Reducer({
                        type: "INTEREST_RATE",
                        vehicle_interest_rate: e.target.value,
                      })
                    );
                  }}
                />
                <span className="inline-flex items-center space-x-2 px-3 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                  <span className="text-grey2">%</span>
                </span>
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"interest_rate"}
              />
            </div>

            <div className="mx-5 sm:col-span-6">
              <label
                htmlFor="product_category"
                className="text-left block text-lg font-sans text-gray-700"
              >
                Product Category
              </label>
              <div className="mt-2 text-left w-2/3 min-w-full overflow-y-auto overflow-hidden min-h-full">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    value="Pre-Owned Supercar"
                    checked={
                      vehicleListingProductCategory === "Pre-Owned Supercar"
                    }
                    onChange={ProductCategoryHandler}
                  />
                  <span className="ml-2">Pre-Owned Supercar</span>
                </label>

                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className="form-radio"
                    value="Pre-Owned Highline"
                    checked={
                      vehicleListingProductCategory === "Pre-Owned Highline"
                    }
                    onChange={ProductCategoryHandler}
                  />
                  <span className="ml-2">Pre-Owned Highline</span>
                </label>

                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className="form-radio"
                    value="Pre-Owned Mainline"
                    checked={
                      vehicleListingProductCategory === "Pre-Owned Mainline"
                    }
                    onChange={ProductCategoryHandler}
                  />
                  <span className="ml-2">Pre-Owned Mainline</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <button
                    className="ml-2 text-policaroBlue"
                    onClick={ClearProductCategoryHandler}
                  >
                    Clear selection
                  </button>
                </label>
              </div>
            </div>

            <div className="sm:col-span-6">
              <label
                htmlFor="residuals"
                className="ml-5 text-left block text-lg font-sans text-gray-700"
              >
                Residual Table
              </label>
              {/* When rendered using Edit Vehicle button this tables will be the options */}
              {IS_FROM_ADD_VEHICHLE_BTN == false &&
              vehicleListingProductCategory == "" ? (
                <p className="pl-5 pt-2 text-left text-grey2">
                  Select a product category to add residuals.
                </p>
              ) : IS_FROM_ADD_VEHICHLE_BTN == false &&
                product_category == vehicleListingProductCategory ? (
                <ResidualTable
                  residualTable={formatDBResiduals(residuals)}
                  residualTableHander={Residual_Table_Handler}
                  productCategory={vehicleListingProductCategory}
                />
              ) : (
                [
                  vehicleListingProductCategory == "Pre-Owned Supercar" &&
                    IS_FROM_ADD_VEHICHLE_BTN == false && (
                      <ResidualTable
                        key={"Pre-Owned Supercar"}
                        residualTable={DefaultTables.tableDataSuperCar}
                        residualTableHander={Residual_Table_Handler}
                        productCategory={vehicleListingProductCategory}
                      />
                    ),
                  vehicleListingProductCategory == "Pre-Owned Mainline" &&
                    IS_FROM_ADD_VEHICHLE_BTN == false && (
                      <ResidualTable
                        key={"Pre-Owned Mainline"}
                        residualTable={DefaultTables.tableDataMainLine}
                        residualTableHander={Residual_Table_Handler}
                        productCategory={vehicleListingProductCategory}
                      />
                    ),
                  vehicleListingProductCategory == "Pre-Owned Highline" &&
                    IS_FROM_ADD_VEHICHLE_BTN == false && (
                      <ResidualTable
                        key={"Pre-Owned Highline"}
                        residualTable={DefaultTables.tableDataHighline}
                        residualTableHander={Residual_Table_Handler}
                        productCategory={vehicleListingProductCategory}
                      />
                    ),
                ]
              )}{" "}
              {/* When rendered using Add Vehicle button this tables will be the options */}
              {vehicleListingProductCategory == "Pre-Owned Supercar" &&
                IS_FROM_ADD_VEHICHLE_BTN == true && (
                  <ResidualTable
                    key={"Pre-Owned Supercar"}
                    residualTable={DefaultTables.tableDataSuperCar}
                    residualTableHander={Residual_Table_Handler}
                    productCategory={vehicleListingProductCategory}
                  />
                )}
              {vehicleListingProductCategory == "Pre-Owned Mainline" &&
                IS_FROM_ADD_VEHICHLE_BTN == true && (
                  <ResidualTable
                    key={"Pre-Owned Mainline"}
                    residualTable={DefaultTables.tableDataMainLine}
                    residualTableHander={Residual_Table_Handler}
                    productCategory={vehicleListingProductCategory}
                  />
                )}
              {vehicleListingProductCategory == "Pre-Owned Highline" &&
                IS_FROM_ADD_VEHICHLE_BTN == true && (
                  <ResidualTable
                    key={"Pre-Owned Highline"}
                    residualTable={DefaultTables.tableDataHighline}
                    residualTableHander={Residual_Table_Handler}
                    productCategory={vehicleListingProductCategory}
                  />
                )}
            </div>

            <div className="ml-5 sm:col-span-3 mr-5">
              <label
                htmlFor="vehicle_status"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Vehicle Status{" "}
              </label>
              {/* <div className="border-2 border-opacity-50 border-textInputBorder rounded-md h-10"> */}
              <div className={isValidInputClass("availability")}>
                <select
                  id="vehicle_status"
                  name="vehicle_status"
                  className="pl-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full sm:text-sm border-gray-300 rounded-md"
                  value={vehicleListingAvailability}
                  onChange={(e) => {
                    setVehicleListingStatus(e.target.value);
                    dispatch(
                      addVehicleActions.Listing_Details_Reducer({
                        type: "STATUS",
                        vehicle_availability: e.target.value,
                      })
                    );
                  }}
                >
                  <option value="NOT AVAILABLE">Not Available</option>
                  <option value="PENDING ARRIVAL">Pending Arrival</option>
                  <option value="AVAILABLE">Available</option>
                  <option value="PENDING SALE">Pending Sale</option>
                  <option value="SOLD">Sold</option>
                </select>
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"availability"}
              />
            </div>

            <div className="ml-5 sm:col-span-2 mt-5">
              <div className="flex items-stretch flex-grow focus-within:z-10 border-2 border-opacity-50 border-textInputBorder rounded-md h-10 ">
                <div className="flex items-center">
                  <div className="pt-1 pl-2">
                    <Switch
                      value={enabled}
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? "bg-indigo-600" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        className={classNames(
                          enabled ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      >
                        <span
                          className={classNames(
                            enabled
                              ? "opacity-0 ease-out duration-100"
                              : "opacity-100 ease-in duration-200",
                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg
                            className="h-3 w-3 text-gray-400"
                            fill="none"
                            viewBox="0 0 12 12"
                          >
                            <path
                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span
                          className={classNames(
                            enabled
                              ? "opacity-100 ease-in duration-200"
                              : "opacity-0 ease-out duration-100",
                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg
                            className="h-3 w-3 text-indigo-600"
                            fill="currentColor"
                            viewBox="0 0 12 12"
                          >
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                          </svg>
                        </span>
                      </span>
                    </Switch>
                  </div>

                  <div className="justify-end">
                    <label className="ml-1 mt-2 text-policaroBlue">
                      Include on leasing site
                    </label>
                  </div>
                </div>
              </div>
              <InputErrorSection
                inputErrors={inputErrors}
                inputField={"includeOnLeasing"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
