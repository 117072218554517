import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ReactComponent as NewPolicaroLogo } from "../component/policaro-logo-image/policaroLogo.svg";
import { loginUserInfo } from "../store/login-actions";

function validateEmail(email) {
  const regexEmailFormat =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regexEmailFormat.test(email);
}
``;

// THIS FILE WILL BE REFRACTORED SOON
export default function ChangeTempPassword() {
  let history = useHistory();
  const dispatch = useDispatch();

  let FRONT_END_ERROR = 1;
  let PASSWORD_NOT_MATCH = 2;
  let EMAIL_FORMAT_INVALID = 3;

  const [frontEndErrorCode, setFrontEndErrorCode] = useState(0); // to save error messages in the frontend input validation

  const [userName, setUserName] = useState("");
  const [firstNewPassword, setFirstNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [isInputValid, setIsInputValid] = useState(true);
  const ERROR_LOGIN_FLAG = useSelector((state) => state.login.error_login_flag);
  const ERROR_CODE = useSelector((state) => state.login.error_code);
  const temporaryUserPassword = useSelector(
    (state) => state.login.temporary_password
  );
  const userNameHandler = (event) => {
    setUserName(event.target.value);
  };

  const firstNewPasswordHandler = (event) => {
    setFirstNewPassword(event.target.value);
  };

  const confirmNewPasswordHandler = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const changeTemporaryPassword = (event) => {
    event.preventDefault();
    // Check if the user name is empty
    if (
      userName == null ||
      firstNewPassword.length == 0 ||
      confirmNewPassword.length == 0
    ) {
      setIsInputValid(false);
      setFrontEndErrorCode(FRONT_END_ERROR);
      return;
    }

    // check if password and confirm password are equal
    if (firstNewPassword == confirmNewPassword) {
      let isValid = validateEmail(userName);
      if (isValid) {
        dispatch(
          loginUserInfo(userName, temporaryUserPassword, firstNewPassword)
        ); // call the login again
      } else {
        setIsInputValid(false);
        setFrontEndErrorCode(EMAIL_FORMAT_INVALID);
      }
    } else {
      setIsInputValid(false);
      setFrontEndErrorCode(PASSWORD_NOT_MATCH);
    }
  };

  const promptErrorMessage = (code) => {
    let messageCode = "";

    if (code == FRONT_END_ERROR) {
      messageCode = "All inputs must be valid";
    } else if (code == PASSWORD_NOT_MATCH) {
      messageCode = "Password must match";
    } else if (code == EMAIL_FORMAT_INVALID) {
      messageCode = "Email format is invalid";
    } else {
      if (ERROR_CODE) {
        messageCode = ERROR_CODE;
      }
    }

    return (
      <div className="mt-5">
        <div className="rounded-md bg-sendRecoveryErrorBg py-5 sm:flex sm:items-start sm:justify-between">
          <div className="text-sendRecoveryErrorFontColor text-left mt-3 sm:mt-0 sm:ml-4 font-normal">
            {messageCode}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Error login flag will only be true if the user is authenticated
    if (ERROR_LOGIN_FLAG) {
      history.push("/main-vehicle-table");
    }
  }, [ERROR_LOGIN_FLAG]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <div>
            <NewPolicaroLogo className="w-36" />
          </div>
        </div>
        <h2 className="mt-6 text-center text-2xl font-bold text-gray-900">
          Inventory Portal
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={changeTemporaryPassword}>
            <h3 className="text-left text-xl font-sans leading-6 font-medium text-textPrimary mb-6">
              Set New Password
            </h3>
            <div>
              <label
                htmlFor="email"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1 ">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={userName}
                  required
                  onChange={userNameHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="text-left block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={firstNewPassword}
                  onChange={firstNewPasswordHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={confirmNewPassword}
                  onChange={confirmNewPasswordHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            {isInputValid == false &&
              (frontEndErrorCode != 0
                ? promptErrorMessage(frontEndErrorCode)
                : promptErrorMessage(ERROR_CODE))}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-sans text-white bg-policaroBlue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={changeTemporaryPassword}
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
