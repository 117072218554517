/* eslint-disable no-unused-vars */
// import "./Vehicle-Photo-Upload-Section.css";
import "./Second-Version-Vehicle-Form/Vehicle-Photo-Upload-Section.css";
import "./Modal-Image.css";
import React, { Fragment } from "react";
import { Carousel } from "react-responsive-carousel";
import { XIcon, CheckIcon } from "@heroicons/react/outline";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default // Creates the modal that will display the images of this vehicle
function Modal({
  vehicleImageList,
  isOpen,
  openHandler,
  currentSelectedImage,
}) {
  return (
    <>
      {isOpen ? (
        <>
          <div className="max-w-full max-h-3xl justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto">
              <div className="mx-auto px-4 sm:px-1 lg:px-1">
                <div className="relative object-scale-down flex items-center justify-end border-t border-solid border-black-200 border-opacity-100 rounded-b">
                  <Carousel
                    selectedItem={currentSelectedImage}
                    showArrows={true}
                    showThumbs={true}
                    showIndicators={true}
                    swipeable={true}
                    useKeyboardArrows={true}
                    infiniteLoop={true}
                    className="block h-screen"
                    dynamicHeight={false}
                  >
                    {vehicleImageList.map((img, index) => (
                      <div key={index} className="h-full w-full">
                        <img
                          className="object-contain"
                          style={{
                            maxHeight: "80vh",
                            height: "100%",
                            maxWdith: "100%",
                          }}
                          key={index}
                          src={img.url}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <button
                    className="absolute rounded-full top-5 right-5 text-white font-bold uppercase px-10 py-10 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    // onClick={() => setOpen(false)}
                    onClick={openHandler(false)}
                  >
                    <XIcon
                      className=" h-10 w-10 bg-emperor-500 absolute rounded-full top-5 right-5 py-2 px-2"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
