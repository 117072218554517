import BasicInformation from "./Basic-Information-Section";
import VehicleInformation from "./Vehicle-Information-Section";
import PhotoUpload from "./Vehicle-Photo-Upload-Section";
import ListingDetails from "./Vehicle-Listing-Section";
import DiscardChangesModal from "./Modals/Discard-Changes-Modal";
import RemoveVehicleModal from "./Modals/Remove-Vehicle-Modal";
import InputsInvalidModal from "./Modals/Input-Invalid-Modal";

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { VehicleInformationData } from "../../model/Vehicle-Info/Vehicle-Info";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { addVehicleActions } from "../../store/add-vehicle-slice";
import { Fragment, useRef } from "react";
import { refreshTable } from "../../store/vehicle-list-slice";
import { Redirect } from "react-router";
import * as Validator from "validatorjs";

import { AddVehicleInfoActionCreator } from "../../store/add-vehicle-action";

export default function AddVehicle({
  // eslint-disable-next-line no-unused-vars
  vehicle_information,
  isFromAddVehicleBtn,
}) {
  const userTokenKey = localStorage.getItem("TOKEN_KEY");
  if (!userTokenKey) {
    return <Redirect to="/" />;
  }

  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isAllFieldValid = useRef(true);
  // to do a force render to keep all the data on the table updated - TEMPORARY SOLUTION
  const tableState = useSelector((state) => state.vehicle_list.REFRESH_TABLE);

  const determineWhatTableIsOpen = useSelector(
    (state) => state.main_table.isFromMainTable
  );
  // Accepting parameters
  const [vehicleData, setVehicleData] = useState(
    location.state.vehicle_information
  );

  let isFromAddBtn = location.state.isFromAddVehicleBtn;

  const [removeVehicleModal, setOpenRemoveVehicleModal] = useState(false);
  const [discardModal, setDiscardModal] = useState(false);
  const [invalidInputsModal, setInvalidInputsModal] = useState(false);
  const readyToSendFlag = useSelector((state) => state.add_vehicle.SEND_FLAG);

  const removeVehicleModalHandler = (state) => () => {
    setOpenRemoveVehicleModal(state);
  };

  const discardModalHandler = (state) => () => {
    setDiscardModal(state);
  };

  const inputInvalidModalHandler = (state) => () => {
    setInvalidInputsModal(state);
  };

  const Class_BasicInfo_Year = useSelector((state) => state.add_vehicle.year);
  const Class_BasicInfo_Make = useSelector((state) => state.add_vehicle.make);
  const Class_BasicInfo_Model = useSelector((state) => state.add_vehicle.model);
  const Class_BasicInfo_Odometer = useSelector(
    (state) => state.add_vehicle.odometer
  );
  const Class_BasicInfo_Metric = useSelector(
    (state) => state.add_vehicle.metric
  );
  const Class_BasicInfo_Vin = useSelector((state) => state.add_vehicle.vin);
  const Class_BasicInfo_StockNumber = useSelector(
    (state) => state.add_vehicle.stock_number
  );

  const Class_BasicInfo_DealerName = useSelector(
    (state) => state.add_vehicle.dealer_name
  );

  const Class_VehicleDetails_DriveTrain = useSelector(
    (state) => state.add_vehicle.drivetrain
  );
  const Class_VehicleDetails_Transmission = useSelector(
    (state) => state.add_vehicle.transmission
  );

  const Class_VehicleDetails_ExteriorColour = useSelector(
    (state) => state.add_vehicle.exterior_colour
  );

  const Class_VehicleDetails_InteriorColour = useSelector(
    (state) => state.add_vehicle.interior_colour
  );

  const Class_VehicleDetails_Trim = useSelector(
    (state) => state.add_vehicle.trim
  );

  const Class_VehicleDetails_Description = useSelector(
    (state) => state.add_vehicle.description
  );

  const Class_Vehicle_Photos = useSelector(
    (state) => state.add_vehicle.car_images
  );

  const Class_ListingDetails_Price = useSelector(
    (state) => state.add_vehicle.price
  );

  const Class_ListingDetails_Currency = useSelector(
    (state) => state.add_vehicle.currency
  );

  const Class_ListingDetails_InterestRate = useSelector(
    (state) => state.add_vehicle.interest_rate
  );

  const Class_ListingDetails_ProductCategory = useSelector(
    (state) => state.add_vehicle.product_category
  );

  const Class_ListingDetails_ResidualTable = useSelector(
    (state) => state.add_vehicle.residual_table
  );

  const Class_ListingDetails_Availability = useSelector(
    (state) => state.add_vehicle.availability
  );

  const Class_ListingDetails_includeLeasing = useSelector(
    (state) => state.add_vehicle.includeOnLeasing
  );

  const initialUpdate = useRef(true);
  const [inputErrors, setInputErrors] = useState({});

  var validateFormData = (data) => {
    const rules = {
      id: "integer",
      year: "required|integer|digits_between:4,4",
      make: "required|string|max:100",
      model: "required|string|max:150",
      odometer: "required|numeric|digits_between:0,9",
      odometerUnit: "required|in:KM,MI",
      vin: "required|string|max:20",
      stockNumber: "required|string|max:30",
      drivetrain: "string|in:FWD,RWD,AWD,4WD",
      transmission: "string|in:AUTO,MAN",
      exteriorColor: "string|max:100",
      interiorColor: "string|max:100",
      trim: "string|max:100",
      description: "string|max:10000",
      price: [
        "required",
        "regex:/^[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{2})?$/",
      ],
      availability:
        "required|string|in:AVAILABLE,NOT AVAILABLE,PENDING ARRIVAL,PENDING SALE,SOLD",
      includeOnLeasing: "required|boolean", //Should this have an in constraint?
      currency: "required|in:USD,CAD",
      interest_rate: "numeric|max:29.9|min:0",
      product_category:
        "string|in:Pre-Owned Supercar,Pre-Owned Highline,Pre-Owned Mainline", //in constraint?
      dealer_name: "string|max:100",
      creation_date: "integer",
    };
    const alteredAttributes = {
      stockNumber: "stock number",
      odometerUnit: "odometer unit",
      exteriorColor: "exterior colour",
      interiorColor: "interior colour",
      includeOnLeasing: "include on leasing site",
      interest_rate: "interest rate",
      product_category: "product category",
      dealer_name: "dealer name",
    };
    const customErrorMessages = {
      "digits_between.year": "The year must be a four digit number.",
      "integer.year": "The year must be a four digit number.",
      "numeric.odometer":
        "The odometer must be a number less than nine digits long.",
      "digits_between.odometer":
        "The odometer must be a number less than nine digits long.",
      "regex.price":
        "The price must be a number with no more than two decimal places (Eg. 1,000.00).",
    };

    var validation = new Validator(data, rules, customErrorMessages);
    validation.setAttributeNames(alteredAttributes);
    if (validation.errors) {
      setInputErrors(validation.errors.all());
      isAllFieldValid.current = false;
    }

    return validation.passes();
  };

  const EditOrAddVehicleHandler = () => {
    if (readyToSendFlag == true) {
      dispatch(addVehicleActions.UpdateFlag({ send: false }));
    } else {
      dispatch(addVehicleActions.UpdateFlag({ send: true }));
    }

    let newData = "";

    if (vehicleData.id != null) {
      newData = new VehicleInformationData(
        vehicleData.id,
        // Basic information
        Class_BasicInfo_Year,
        Class_BasicInfo_Make,
        Class_BasicInfo_Model,
        Class_BasicInfo_Odometer,
        Class_BasicInfo_Metric,
        Class_BasicInfo_Vin,
        Class_BasicInfo_StockNumber,
        Class_BasicInfo_DealerName,
        // Vehicle details
        Class_VehicleDetails_DriveTrain,
        Class_VehicleDetails_Transmission,
        Class_VehicleDetails_ExteriorColour,
        Class_VehicleDetails_InteriorColour,
        Class_VehicleDetails_Trim,
        Class_VehicleDetails_Description,
        // Photos
        Class_Vehicle_Photos,
        // Listing details
        Class_ListingDetails_Price,
        Class_ListingDetails_Currency,
        Class_ListingDetails_InterestRate,
        Class_ListingDetails_ProductCategory,
        Class_ListingDetails_ResidualTable,
        Class_ListingDetails_Availability,
        Class_ListingDetails_includeLeasing
      );
    } else {
      newData = new VehicleInformationData(
        // Basic information
        Class_BasicInfo_Year,
        Class_BasicInfo_Make,
        Class_BasicInfo_Model,
        Class_BasicInfo_Odometer,
        Class_BasicInfo_Metric,
        Class_BasicInfo_Vin,
        Class_BasicInfo_StockNumber,
        Class_BasicInfo_DealerName,
        // Vehicle details
        Class_VehicleDetails_DriveTrain,
        Class_VehicleDetails_Transmission,
        Class_VehicleDetails_ExteriorColour,
        Class_VehicleDetails_InteriorColour,
        Class_VehicleDetails_Trim,
        Class_VehicleDetails_Description,
        // Photos
        Class_Vehicle_Photos,
        // Listing details
        Class_ListingDetails_Price,
        Class_ListingDetails_Currency,
        Class_ListingDetails_InterestRate,
        Class_ListingDetails_ProductCategory,
        Class_ListingDetails_ResidualTable,
        Class_ListingDetails_Availability,
        Class_ListingDetails_includeLeasing
      );
    }

    if (!validateFormData(newData)) {
      setInvalidInputsModal(true);
      return;
    }

    setVehicleData(newData);
    dispatch(addVehicleActions.SendToDb({ db_flag: true }));
  };

  // UseEffect that sends the form to the database.
  useEffect(() => {
    if (initialUpdate.current) {
      initialUpdate.current = false;
      return;
    }

    if (isAllFieldValid.current) {
      dispatch(AddVehicleInfoActionCreator(vehicleData))
        .then((returnObj) => {
          if (returnObj && returnObj.code == 1) {
            throw returnObj.message;
          }

          // Force update the main table to make sure all the data is correct
          tableState == true
            ? dispatch(refreshTable({ forceUpdate: false }))
            : dispatch(refreshTable({ forceUpdate: true }));

          // Redirect to what table the user was before entering the form
          determineWhatTableIsOpen
            ? history.push("/main-vehicle-table")
            : history.push("/sold-archive");
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [vehicleData]);

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  pt-10 border-round bg-athens-gray-500">
        <div className="max-w-3xl mx-auto justify-start">
          <div className="flex content-start items-stretch">
            <div>
              <ArrowLeftIcon
                className="w-5 h-5 text-policaroBlue"
                onClick={() => {
                  determineWhatTableIsOpen // if main table is open, go back to main. If not go back to sold
                    ? history.push("/main-vehicle-table")
                    : history.push("/sold-archive");
                }}
              />
            </div>

            <div className="h-5">
              <button
                className="ml-3 text-policaroBlue"
                onClick={() => {
                  determineWhatTableIsOpen // if main table is open, go back to main. If not go back to sold
                    ? history.push("/main-vehicle-table")
                    : history.push("/sold-archive");
                }}
              >
                Back{" "}
              </button>{" "}
            </div>
          </div>

          <div className="mt-3 flex justify-between align-middle">
            <div>
              <span className="mt-3 text-2xl font-semibold">Add Vehicle</span>
            </div>
            <div>
              <span className="mt-1 text-grey1">
                <span className="text-removeButtonBackground">*</span> Required
                field
              </span>
            </div>
          </div>
        </div>
      </div>

      <BasicInformation parentVehicle={vehicleData} inputErrors={inputErrors} />
      <VehicleInformation
        parentVehicle={vehicleData}
        inputErrors={inputErrors}
      />
      <PhotoUpload parentVehicle={vehicleData} />
      <ListingDetails
        parentVehicle={vehicleData}
        inputErrors={inputErrors}
        fromAddButton={isFromAddVehicleBtn}
        validateInput={isAllFieldValid}
      />

      {/* Add vehicle form buttons, can be refractored soon */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  pb-10 border-round bg-athens-gray-500">
        <div className="max-w-3xl mx-auto justify-start">
          <div className="relative flex content-start items-stretch">
            <div>
              <button
                type="button"
                className="inline-flex items-center justify-center w-32 h-12 font-sans bg-policaroBlue mr-5  px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={EditOrAddVehicleHandler}
              >
                {isFromAddBtn == true || isFromAddBtn == null
                  ? "Add Vehicle"
                  : "Save Changes"}
              </button>
              <button
                type="button"
                className="w-34 h-12 font-sans inline-flex items-center text-black justify-center bg-white mr-5  px-2.5 py-1.5 border border-opacity-100 text-sm font-medium rounded shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setDiscardModal(true)}
              >
                {isFromAddBtn == true || isFromAddBtn == null
                  ? "Discard"
                  : "Discard Changes"}
              </button>

              {isFromAddBtn == false && (
                <button
                  type="button"
                  className="absolute right-0 w-34 h-12 font-sans inline-flex items-center text-black justify-center bg-white px-2.5 py-1.5 border border-opacity-100 text-sm font-medium rounded shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpenRemoveVehicleModal(true)}
                >
                  Remove Vehicle
                </button>
              )}

              <RemoveVehicleModal
                vehicleObject={vehicleData}
                removeModalState={removeVehicleModal}
                removeModalHandler={removeVehicleModalHandler}
              />

              <DiscardChangesModal
                discardModalState={discardModal}
                discardModalHandler={discardModalHandler}
              />

              <InputsInvalidModal
                invalidModalState={invalidInputsModal}
                invalidModalHandler={inputInvalidModalHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
