// Should ve refractored soon and be removed from here.
import { setToastInformation } from "./toast-message-slice";
export const DeleteVehicleInfoActionCreator = (vehicleToDelete) => {
  return async (dispatch) => {
    const DeleteVehicleToDB = async (vehicleToDelete) => {
      const response = await fetch(`/vehicle/${vehicleToDelete.id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        let deleteErrorMessage = await response.json();
        alert(deleteErrorMessage);
      } else {
        // Setting the toast message for delete
        dispatch(
          setToastInformation({
            status: "Deleted",
            name: `${vehicleToDelete.year} ${vehicleToDelete.make} ${vehicleToDelete.model}`,
            showToast: true,
          })
        );
      }
    };

    try {
      DeleteVehicleToDB(vehicleToDelete);
    } catch (error) {
      alert(error.message);
    }
  };
};
