import { sendRecoveryEmailAction } from "./send-recovery-email-slice";

export const sendRecoveryEmailActionCreator = (email) => {
  let SEND_RECOVERY_EMAIL_URL = "/auth/recovery";
  return async (dispatch) => {
    const UserErrorRecoveryFeedback = (code) => {
      dispatch(
        sendRecoveryEmailAction.saveErrorSendRecoveryCode({
          response_code: code,
        })
      );
    };

    const updateEmailSentSuccessfully = () => {
      dispatch(sendRecoveryEmailAction.updateSendSuccessfullyFlag());
    };

    const sendPasswordRecoveryEmail = async (email) => {
      const response = await fetch(SEND_RECOVERY_EMAIL_URL, {
        method: "POST",
        headers: { "Content-type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          username: email,
        }),
      });

      // if not OK
      if (!response.ok) {
        //console.log("NOT OK");
        response.json().then((data) => {
          UserErrorRecoveryFeedback(data.responseCode);
        });
      } else {
        //console.log("OK");
        // Seth the email_send_successfully to true
        updateEmailSentSuccessfully();
        response.json().then((data) => {
          UserErrorRecoveryFeedback(data.responseCode);
        });
      }
    };

    try {
      await sendPasswordRecoveryEmail(email);
    } catch (error) {
      console.log(error);
    }
  };
};
