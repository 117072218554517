import { createSlice } from "@reduxjs/toolkit";

const addVehicleSlice = createSlice({
  name: "Add Vehicle Information",
  initialState: {
    // Basic information
    year: "",
    make: "",
    model: "",
    odometer: "",
    metric: "",
    vin: "",
    stock_number: "",
    dealer_name: "",

    // Vehicle details
    drivetrain: "",
    transmission: "",
    exterior_colour: "",
    interior_colour: "",
    trim: "",
    description: "",

    // Photos
    car_images: [],

    // Listing details
    price: "",
    currency: "",
    interest_rate: "",
    product_category: "",
    residual_table: "",
    availability: "",
    includeOnLeasing: false,

    createdDate: "",
    SEND_FLAG: false,
    ADD_VEHICLE_FLAG: "", // flags to know if the add vehicle form is rendered from add button or edit button on side panel
    SEND_TO_DB: false, // to know if all required fields are filled
  },
  reducers: {
    // First 4 reducers is connected to each component onChange on each input fields
    Basic_Information_Reducer(state, action) {
      switch (action.payload.type) {
        case "YEAR":
          state.year = action.payload.vehicle_year;
          break;
        case "MAKE":
          state.make = action.payload.vehicle_make;
          break;
        case "MODEL":
          state.model = action.payload.vehicle_model;
          break;
        case "ODOMETER":
          state.odometer = action.payload.vehicle_odometer;
          break;
        case "METRIC":
          state.metric = action.payload.vehicle_metric;
          break;
        case "VIN":
          state.vin = action.payload.vehicle_vin;
          break;
        case "STOCK_NUMBER":
          state.stock_number = action.payload.vehicle_stock_number;
          break;
        case "DEALER_NAME":
          state.dealer_name = action.payload.vehicle_dealer_name;
          break;
      }
    },

    Vehicle_Details_Reducer(state, action) {
      switch (action.payload.type) {
        case "DRIVETRAIN":
          state.drivetrain = action.payload.vehicle_drivetrain;
          break;
        case "TRANSMISSION":
          state.transmission = action.payload.vehicle_transmission;
          break;
        case "EXTERIOR_COLOUR":
          state.exterior_colour = action.payload.vehicle_exterior_colour;
          break;
        case "INTERIOR_COLOUR":
          state.interior_colour = action.payload.vehicle_interior_colour;
          break;
        case "TRIM":
          state.trim = action.payload.vehicle_trim;
          break;
        case "DESCRIPTION":
          state.description = action.payload.vehicle_description;
          break;
      }
    },

    Upload_Photos_Reducer(state, action) {
      state.car_images = action.payload.vehicle_photos;
    },

    Listing_Details_Reducer(state, action) {
      switch (action.payload.type) {
        case "PRICE":
          state.price = action.payload.vehicle_price;
          break;
        case "CURRENCY":
          state.currency = action.payload.vehicle_currency;
          break;
        case "INTEREST_RATE":
          state.interest_rate = action.payload.vehicle_interest_rate;
          break;
        case "PRODUCT_CATEGORY":
          state.product_category = action.payload.vehicle_product_category;
          break;
        case "RESIDUAL_TABLE":
          state.residual_table = action.payload.vehicle_residual_table;
          break;
        case "STATUS":
          state.availability = action.payload.vehicle_availability;
          break;
        case "ONLEASING":
          state.includeOnLeasing = action.payload.onLeasing;
          break;
      }
    },
    // saving the previous state before the changes is made
    SaveBackupDiscardState(state, action) {
      state.prevState = action.payload.previous_state;
    },

    UpdateFlag(state, action) {
      state.SEND_FLAG = action.payload.send;
    },

    EditOrAddFlag(state, action) {
      state.ADD_VEHICLE_FLAG = action.payload.current_flag;
    },

    SendToDb(state, action) {
      state.SEND_TO_DB = action.payload.db_flag;
    },
  },
});

// Exporting so the slice can be seen from other files
export const addVehicleActions = addVehicleSlice.actions;
export default addVehicleSlice;
