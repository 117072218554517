import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { ReactComponent as NewPolicaroLogo } from "../component/policaro-logo-image/policaroLogo.svg";

function validateEmail(email) {
  const regexEmailFormat =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regexEmailFormat.test(email);
}
``;

export default function VerifyUser() {
  let history = useHistory();

  // Parse URL
  const location = useHistory().location;
  const verificationCode = queryString.parse(location.search); // returns the query object

  // Parse the URL and value from the key "code"
  const [verification_code, setVerificationCode] = useState(
    verificationCode.code
  );

  const [userName, setUserName] = useState("");
  const [isInputValid, setIsInputValid] = useState(true);

  const userNameHandler = (event) => {
    setUserName(event.target.value);
  };

  const verificationCodeHandler = (event) => {
    setVerificationCode(event.target.value);
  };

  // To be refractored soon
  const verifyAccount = async (email, code) => {
    let url = `/auth/verify?user=${email}&code=${code}`;

    const response = await fetch(url);
    if (!response.ok) {
      setIsInputValid(false);
      let errorCode = await response.json();
      alert(errorCode.code);
      return;
    } else {
      // if successful
      history.push("/verify-account-successfully");
      setIsInputValid(true);
      return;
    }
  };

  // To be refractored soon
  const verifyAccountBtnHandler = (event) => {
    event.preventDefault();
    if (userName.length == 0 || verification_code.length == 0) {
      setIsInputValid(false);
      return;
    } else {
      let isValid = validateEmail(userName);
      if (isValid) {
        verifyAccount(userName, verification_code);
      } else {
        // if email is not correct format
        setIsInputValid(false);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <div>
            <NewPolicaroLogo className="w-36" />
          </div>
        </div>
        <h2 className="mt-6 text-center text-2xl font-bold text-gray-900">
          Inventory Portal
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={verifyAccountBtnHandler}>
            <h3 className="text-left text-xl font-sans leading-6 font-medium text-textPrimary mb-6">
              Verify Your Account
            </h3>
            <div>
              <label
                htmlFor="email"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1 ">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={userName}
                  required
                  onChange={userNameHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            {(verificationCode.code == null || verificationCode.code == "") && (
              <div>
                <label
                  htmlFor="email"
                  className="text-left block text-sm font-medium text-gray-700"
                >
                  Verification Code
                </label>
                <div className="mt-1">
                  <input
                    id="code"
                    name="code"
                    type="code"
                    autoComplete="code"
                    required
                    value={verificationCode.code}
                    onChange={verificationCodeHandler}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            )}

            {isInputValid == false && (
              <div className="mt-5">
                <div className="rounded-md bg-sendRecoveryErrorBg py-5 sm:flex sm:items-start sm:justify-between">
                  <div className="text-sendRecoveryErrorFontColor text-left mt-3 sm:mt-0 sm:ml-4 font-normal">
                    All input fields must be valid
                  </div>
                </div>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-sans text-white bg-policaroBlue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={verifyAccountBtnHandler}
              >
                Verfiy Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
