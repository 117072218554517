import { createSlice } from "@reduxjs/toolkit";

const mainTableSlice = createSlice({
  name: "Main table",
  initialState: {
    showPerPageValue: 10,
    currentPageNumber: 1,
    totalVehicles: 0,
    totalPages: 0,
    isFromMainTable: true, // true if main table, false if sold table
  },
  reducers: {
    setShowPerPageValue(state, actions) {
      state.showPerPageValue = actions.payload.show_per_page;
      state.currentPageNumber = 1;
      state.totalPages = Math.ceil(
        state.totalVehicles / state.showPerPageValue
      );
    },

    setCurrentPage(state, actions) {
      state.currentPageNumber = actions.payload.pageNumber;
    },

    setTotalVehicles(state, actions) {
      state.totalVehicles = actions.payload.totalVehicles;
      state.totalPages = Math.ceil(
        state.totalVehicles / state.showPerPageValue
      );
    },

    incrementPage(state) {
      state.currentPageNumber++;
    },

    decrementPage(state) {
      state.currentPageNumber--;
    },

    resetPagination(state) {
      state.currentPageNumber = 1;
      state.totalVehicles = 0;
      state.totalPages = 0;
    },

    determineWhatTableIsOpen(state, actions) {
      state.isFromMainTable = actions.payload.isMain;
    },
  },
});

export const mainTableSliceActions = mainTableSlice.actions;
export default mainTableSlice;
