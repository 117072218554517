import { setToastInformation } from "./toast-message-slice";

export const vehicleList_StarVehicle = (id, year, make, model) => {
  return async (dispatch) => {
    const StarVehicle = async (id) => {
      let starVehicle = `/vehicle/sold/star?id=${id}`;

      const response = await fetch(starVehicle, {
        method: "PUT",
      });

      if (!response.ok) {
        let errorCode = await response.json();
        alert(errorCode["message"]);
      }

      return await response;
    };

    try {
      const responseReturn = await StarVehicle(id, year, make, model);

      if (responseReturn.status === 200) {
        dispatch(
          setToastInformation({
            status: "Starred",
            name: `${year} ${make} ${model}`,
            showToast: true,
          })
        );
      }
    } catch (e) {
      alert(e.message);
    }
  };
};

export const vehicleList_UnStarVehicle = (id, year, make, model) => {
  return async (dispatch) => {
    const UnstarVehicle = async (id) => {
      let unstarVehicle = `/vehicle/sold/star/${id}`;

      const response = await fetch(unstarVehicle, {
        method: "DELETE",
      });

      if (!response.ok) {
        let errorCode = await response.json();
        alert(errorCode["message"]);
      }
      return await response;
    };

    try {
      const returnResponse = await UnstarVehicle(id, year, make, model);
      if (returnResponse.status === 200) {
        dispatch(
          setToastInformation({
            status: "Unstarred",
            name: `${year} ${make} ${model}`,
            showToast: true,
          })
        );
      }
    } catch (e) {
      alert(e.message);
    }
  };
};
