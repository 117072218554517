import { createSlice } from "@reduxjs/toolkit";

export const vehicleListSlice = createSlice({
  name: "vehicle-list",
  initialState: {
    vehicles: [],
    REFRESH_TABLE: false,
  },
  reducers: {
    changePage: (state, action) => {
      return {
        vehicles: action.payload.vehicles,
        currentPage: action.payload.page,
        firstLoad: true,
      };
    },

    refreshTable(state, action) {
      state.REFRESH_TABLE = action.payload.forceUpdate;
    },
  },
});

export const { changePage, refreshTable } = vehicleListSlice.actions;

export default vehicleListSlice.reducer;
